import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { UserContext } from './Components/UserContext';

export default function Navbar() {
    const { handleLogout } = useContext(UserContext);

    return (
        <nav className="menu">
            <nav className="nav">
                <span className="title1">GPTDemo</span>
                <ul>
                    <li>
                        <Link to="/reports">Reports</Link>
                    </li>
                    <li>
                        <Link to="/collections">Collections</Link>
                    </li>
                    <li>
                        <Link to="/templates">Templates</Link>
                    </li>
                    <li>
                        <Link to="/interactions">Interactions</Link>
                    </li>
                    <li>
                        <Link to="/demo">Demo</Link>
                    </li>
                    <li>
                        <Button className="link" type="link" onClick={handleLogout}>
                            Logout
                        </Button>
                    </li>
                </ul>
            </nav>
        </nav>
    );
}