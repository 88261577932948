import React, { useState, useEffect, useContext, useRef } from 'react';
import { Avatar, Row, Col, Spin, Card, notification, Button, Modal, Tooltip } from 'antd';
import { UserOutlined, RobotOutlined } from '@ant-design/icons';
import { UserContext } from '../UserContext';
import axiosInstance from '../axiosInstance';
import API_ENDPOINTS from '../../apiConfig';
import TextArea from 'antd/es/input/TextArea';
import CommentParser from './CommentParser';
import Reports from '../../Pages/Reports';

const ChatAgent = () => {

    const initialMessage = "Hello, how can I help you?";
    const { uid, token } = useContext(UserContext)
    const [resultLoading, setResultLoading] = useState(false)
    const [conversation, setConversation] = useState(
        [
            {
                type: "ai",
                // content: initialMessage,
                elements: [
                    { "text": initialMessage },
                ]
            }
        ]
    );
    const messagesEndRef = useRef(null);
    const [question, setQuestion] = useState('');

    const sendQuestion = async (query) => {
        if (!query.trim()) return;
        if (query === 'start_comment_parser')
            return showModal();

        setResultLoading(true);
        setConversation(prevConversation => [...prevConversation, { type: 'human', elements: [{ text: query }] }]);

        try {
            let url = `${API_ENDPOINTS.interactions}/chat_agent?user_id=${uid}`;
            const response = await axiosInstance(token).post(url, {
                query: query
            });

            let elements = response.data.response.elements;
            // let responseContent = responseList.map(item => {
            //     if (item.text) {
            //         return item.text;
            //     }
            //     else if (item.label && item.value) {
            //         return `label: ${item.label}, value: ${item.value}`;
            //     }
            // }).join('\n');

            setConversation(prev => [...prev, { type: 'ai', elements: elements }])

        } catch (error) {
            console.log(error);

            // Display error notification to the user
            notification.error({
                message: 'Error',
                description: 'Failed to send question. Please try again later.',
            });
        } finally {
            setResultLoading(false); // Set loading state to false after the request is completed
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleOptionClick = (value) => {
        sendQuestion(value);
    };

    useEffect(() => {
        scrollToBottom();
    }, [conversation]);

    const clearAll = () => {
        setConversation([{ type: "ai", elements: [{ "text": initialMessage },] }]);
        setQuestion('');
    };

    const clearChat = () => {
        setConversation([{ type: "ai", elements: [{ "text": initialMessage },] }]);
    };

    // State for modal visibility
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '70vh', width: '100%' }}>
            <div style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                flexGrow: 1,
                maxWidth: '85vw',
                width: '60%',
                padding: '10px',
            }}>
                {conversation.map((entry, index) => (
                    <div key={index} style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', marginBottom: '10px' }}>
                        <div style={{ flexShrink: 0, width: '50px' }}>
                            <Avatar style={{ backgroundColor: entry.type === 'human' ? '#87d068' : '#f56a00' }} icon={entry.type === 'human' ? <UserOutlined /> : <RobotOutlined />} />
                        </div>
                        <Card size="small" style={{ whiteSpace: 'pre-wrap' }}>
                            {entry.elements.map((element, elIndex) => (
                                element.text ? (
                                    <p key={elIndex} style={{ margin: '0' }}>{element.text}</p>
                                ) : (
                                    <Tooltip key={elIndex} title={`value: ${element.value}`}>
                                        <Button
                                            onClick={() => handleOptionClick(element.value)}
                                            style={{ display: 'block', margin: '5px 0' }}
                                        >
                                            {element.label}
                                        </Button>
                                    </Tooltip>
                                )
                            ))}
                        </Card>
                    </div>
                ))}
                <div ref={messagesEndRef} />
            </div>
            <div style={{ padding: '10px 20px', backgroundColor: '#fff', width: '100%' }}>
                <Row justify="center" style={{ width: '100%' }}>
                    <Col span={24} style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                        <TextArea
                            rows={2}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            onPressEnter={(e) => sendQuestion(e.target.value)}
                            placeholder="Type your message here..."
                            disabled={resultLoading}
                            style={{
                                width: '60%', // Matches the width of the message box above
                                margin: '0 auto' // Centers the TextArea horizontally
                            }}
                        />
                        {resultLoading && (
                            <Spin style={{
                                position: 'absolute',
                                zIndex: 1,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(255, 255, 255, 0.8)' // Semi-transparent background
                            }} />
                        )}
                    </Col>
                </Row>
                <Row justify="center" style={{ width: '100%', marginTop: '10px' }}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button danger onClick={clearChat} style={{ marginRight: '8px' }} disabled={resultLoading}>
                            Clear Chat
                        </Button>
                        <Button danger onClick={clearAll} style={{ marginRight: '8px' }} disabled={resultLoading}>
                            Clear All
                        </Button>
                        <Button onClick={showModal} style={{ marginRight: '8px' }} disabled={resultLoading}>
                            Comment Parser
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => sendQuestion(question)}
                            loading={resultLoading}
                            disabled={resultLoading || !question.trim()}>
                            Send
                        </Button>
                    </Col>
                </Row>
                <Modal
                    title="Comment Parser"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    width="90%"
                >
                    <CommentParser
                        selectedCollectionKey="ed69934d-3f58-4b95-ac3d-245a207d813c"
                        selectedCollection="Comments Dataset"
                    />
                </Modal>
            </div>
        </div>
    );
}
export default ChatAgent;
