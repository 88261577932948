import  { useEffect, useRef } from 'react';

const Terminal = ({text, setText}) => {
    const textareaRef = useRef(null);

    //Scroll the terminal down whenever new text is added
    useEffect(() => {
        if (textareaRef.current) {
          textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
        }
      }, [text]);


    return (
        <div>
            <textarea className="terminal_log" ref={textareaRef} value={text}/>
        </div>
    );
};


export default Terminal;




