import React, { useState, useEffect, useContext, useRef } from 'react';
import { Avatar, Row, Col, Spin, Collapse, Card, Space, message, notification, Input, Select, Button, List, Typography } from 'antd';
import { UserOutlined, RobotOutlined } from '@ant-design/icons';
import { UserContext } from '../UserContext';
import axiosInstance from '../axiosInstance';
import API_ENDPOINTS from '../../apiConfig';
import { WordCloud } from '@antv/g2plot';
import TextArea from 'antd/es/input/TextArea';


const LeadGenerator = ({
  selectedCollectionKey,
  selectedCollection,
  ...otherProps }) => {

  const initialMessage = "Hello, how can I help you?";

  const { uid, token } = useContext(UserContext)
  const { Option } = Select;

  const [resultLoading, setResultLoading] = useState(false)
  const [fileList, setFileList] = useState([]);
  const [selectedFileName, setSelectedFileName] = useState('');
  const [conversation, setConversation] = useState([{ type: "bot", text: initialMessage }]);
  const messagesEndRef = useRef(null);
  const [question, setQuestion] = useState('');

  useEffect(() => {
    setFileList([]);
    setSelectedFileName('');
    fetchFilesList(selectedCollectionKey);
  }, [selectedCollectionKey]);  // fetch files list when selectedCollectionKey changes


    const fetchFilesList = (key) => {
        if (key === undefined || key === '') {
            console.log('Key is undefined. Axios request not made.');
            return;
        }

        axiosInstance(token)
            .get(`${API_ENDPOINTS.collectionsUpload}?user_id=${uid}&collection_id=${key}`)
            .then((response) => {
                const fileNames = response.data.data.map((file) => file.file_name);
                setFileList(fileNames);
            })
            .catch((error) => {
                console.log(error);
                if (error.response) {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to pull list of collection. Please try again later.',
                    });
                }
            });
    };

  const sendQuestion = async (event) => {
    event.preventDefault(); // Prevent the default Enter key behavior
    if (!selectedFileName) {
      message.warning('Please select a file!');
    }
    else {
      setResultLoading(true); // Set loading state to true
      setConversation(prevConversation => [...prevConversation, { type: 'user', text: question }]);
      try {
        let file_key = uid + "/" + selectedCollectionKey + "/" + selectedFileName;
        let url = `${API_ENDPOINTS.interactions}/lead_generator?user_id=${uid}&collection_id=${selectedCollectionKey}&file_key=${file_key}`;
        const response = await axiosInstance(token).post(url, {
          query: question,
        });
        setConversation(prevConversation => [...prevConversation, { type: 'bot', text: response.data.response }]);
      } catch (error) {
        console.log(error);

        // Display error notification to the user
        notification.error({
          message: 'Error',
          description: 'Failed to send question. Please try again later.',
        });
      } finally {
        setResultLoading(false); // Set loading state to false after the request is completed
      }
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [conversation]);

  const clearConversation = () => {
    setConversation([{ type: "bot", text: initialMessage }]);
    setQuestion('');
  };


  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <Collapse>
        <Card
          hoverable
          size="small"
          align="left"
          className="custom-card"
          title="Select file"
        >
          <div>
            <Select
              showSearch
              optionFilterProp="children"
              style={{ width: '100%' }}
              placeholder="Select a file"
              value={selectedFileName}
              onChange={(value) => setSelectedFileName(value)}
            >
              {fileList.map((filename, index) => (
                <Option key={index} value={filename}>{filename}</Option>
              ))}
            </Select>
          </div>
        </Card>
      </Collapse>
      <div style={{
        overflowY: 'auto',
        overflowX: 'auto',
        maxHeight: '600px',
        maxWidth: '85vw',
        margin: '0 0 10px 0',
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '10px',
      }}>
        {conversation.map((entry, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px 0' }}>
            <Avatar style={{ backgroundColor: entry.type === 'user' ? '#87d068' : '#f56a00' }} icon={entry.type === 'user' ? <UserOutlined /> : <RobotOutlined />} />
            <div style={{ overflowX: 'auto', maxWidth: '78vw', whiteSpace: 'nowrap' }}>
              <Card size="small" style={{ margin: '0 10px', whiteSpace: 'pre-wrap', overflowX: 'auto' }}>
                {entry.text}
              </Card>
            </div>
          </div>
        ))}

        <div ref={messagesEndRef} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Spin spinning={resultLoading} ></Spin>
      </div>
      <Row>
        <Col span={24}>
          <TextArea
            rows={2}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            onPressEnter={sendQuestion}
            placeholder="Type your message here..."
            disabled={resultLoading}
            style={{
              width: '100%',
              height: '15%',
              maxHeight: '4rem',
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button danger onClick={clearConversation} style={{ marginRight: '8px' }} disabled={resultLoading}>
            Clear
          </Button>
          <Button
            type="primary"
            onClick={sendQuestion}
            loading={resultLoading}
            disabled={resultLoading || !question.trim()}>
            {resultLoading ? 'Sending...' : 'Send'}
          </Button>

        </Col>
      </Row>
    </Space>
  )
}

export default LeadGenerator;
