import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useContext, useEffect} from 'react';

import { ConfigProvider } from 'antd';
import axios from 'axios';
import Navbar from './Navbar';
import Login from './Pages/Login';
import Collections from './Pages/Collections';
import Templates from './Pages/Templates';
import Interactions from './Pages/Interactions';
import Reports from './Pages/Reports';
import Demo from './Pages/Demo';
import {
  Select,
  Typography,
} from 'antd';
import { UserContext } from './Components/UserContext';
import { UserProvider } from './Components/UserContext';

function App() {


  const {isLoggedIn, token,
  currentStep, setCurrentStep,
reportsCollectionsList, setReportsCollectionList,
reports, setReportList,
reportLoading, setReportLoading,
selectedReportKey, setSelectedReportKey,
selectedReport, setSelectedReport,
selectedReportsCollectionKey, setSelectedReportsCollectionKey,
selectedCollection, setSelectedCollection,
collectionsList, setCollectionsList,
uploadedFiles, setUploadedFiles,
currentCollectionName, setCurrentCollectionName,
selectedCollectionKey, setSelectedCollectionKey,
isLoading, setIsLoading,
loading, setLoading,
chunkSize, setChunkSize,
overlap, setOverlap,
templates, setTemplates,
jsonData, setJsonData,
selectedTemplateKey, setSelectedTemplateKey,
updatePromptVariables, setUpdatePromptVariables,
updatePromptTemplate, setUpdatePromptTemplate,
selectedTemplateLabel, setSelectedTemplateLabel,
reportTemplates, setReportTemplates,
selectedReportsTemplateKey, setSelectedReportsTemplateKey,
interactionsCollectionsList, setInteractionsCollectionsList,
interactionsTemplatesList, setInteractionsTemplateList,
interactionsConversationsList, setInteractionsConversationsList,
selectedInteractionsCollectionKey, setSelectedInteractionsCollectionKey,
selectedInteractionsCollection, setSelectedInteractionsCollection,
selectedInteractionsTemplateKey, setSelectedInteractionsTemplateKey,
selectedInteractionsTemplate, setSelectedInteractionsTemplate,
selectedInteractionsConversationKey, setSelectedInteractionsConversationKey,
selectedInteractionsConversation, setSelectedInteractionsConversation,
conversationHistory, setConversationHistory,
resultloading, setResultloading,
qaloading, setQaloading,
question, setQuestion,
results, setResults,
sources, setSources,
QASummary, setQASummary,
updatedTemperature, setUpdatedTemperature,
updatedModel, setUpdatedModel,
updatedK, setUpdatedK,
selectedTab, setSelectedTab,
drawerVisible, setDrawerVisible,
promptTemplate, setPromptTemplate,
inputValues, setInputValues,
// openSocket, setOpenSocket, 
docSummaries, setDocSummaries,
  } = useContext( UserContext );

  const { Option } = Select;
  const { Text } = Typography;

  useEffect(() => {
    // Create an Axios instance with default configuration
    const axiosInstance = axios.create();

    // Add a request interceptor
    axiosInstance.interceptors.request.use(
      (config) => {
        // Modify the config object to add headers
        config.headers['Authorization'] = `Bearer ${token}`;
        // Add any other headers you need to include

        return config;
      },
      (error) => {
        // Handle request error
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptor on component unmount
    return () => {
      axiosInstance.interceptors.request.eject();
    };
  }, [token]); // Run the effect only once on component mount

  return (
    <div className="App">
      <ConfigProvider notificationPlacement="center">
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={isLoggedIn ?
               <Reports
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                collections={reportsCollectionsList}
                setCollectionList={setReportsCollectionList}
                reports = {reports}
                setReportList = {setReportList}
                reportLoading = {reportLoading}
                setReportLoading = {setReportLoading}
                selectedTemplateKey = {selectedReportsTemplateKey}
                setSelectedTemplateKey = {setSelectedReportsTemplateKey}
                selectedReport = {selectedReport}
                setSelectedReport = {setSelectedReport}
                selectedReportKey = {selectedReportKey}
                setSelectedReportKey = {setSelectedReportKey}
                selectedCollectionKey = {selectedReportsCollectionKey}
                setSelectedCollectionKey = {setSelectedReportsCollectionKey}
                selectedCollection = {selectedCollection}
                setSelectedCollection = {setSelectedCollection}
                templates = {reportTemplates}
                setTemplates={setReportTemplates}
                // openSocket={openSocket}
                // setOpenSocket={setOpenSocket}
                docSummaries={docSummaries} setDocSummaries={setDocSummaries}
              /> :
              <Navigate to="/login" />} />
            <Route path="/login" element={!isLoggedIn ? <Login /> : <Navigate to="/reports" />} />
            <Route path="/collections" element={isLoggedIn ?
              <Collections
                collections={collectionsList}
                setCollections={setCollectionsList}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                currentCollectionName={currentCollectionName}
                setCurrentCollectionName={setCurrentCollectionName}
                selectedCollectionKey={selectedCollectionKey}
                setSelectedCollectionKey={setSelectedCollectionKey}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                loading={loading}
                setLoading={setLoading}
                Option={Option}
                Text={Text}
                chunkSize={chunkSize}
                setChunkSize={setChunkSize}
                overlap={overlap}
                setOverlap={setOverlap}
              /> :
              <Navigate to="/login" />} />
            <Route path="/demo" element={isLoggedIn ?
              <Demo/> :
              <Navigate to="/login" />} />
            <Route path="/templates" element={isLoggedIn ?
              <Templates
                templates={templates}
                setTemplates={setTemplates}
                jsonData={jsonData}
                setJsonData={setJsonData}
                selectedTemplateKey={selectedTemplateKey}
                setSelectedTemplateKey={setSelectedTemplateKey}
                updatePromptVariables={updatePromptVariables}
                setUpdatePromptVariables={setUpdatePromptVariables}
                updatePromptTemplate={updatePromptTemplate}
                setUpdatePromptTemplate={setUpdatePromptTemplate}
                selectedTemplateLabel={selectedTemplateLabel}
                setSelectedTemplateLabel={setSelectedTemplateLabel}
                reportTemplates={reportTemplates}
                setReportTemplates={setReportTemplates}
                selectedReportTemplateKey={selectedReportsTemplateKey}
                setSelectedReportTemplateKey={setSelectedReportsTemplateKey}
              /> :
              <Navigate to="/login" />} />
            <Route path="/reports" element={isLoggedIn ?
              <Reports
                docSummaries={docSummaries} setDocSummaries={setDocSummaries}
              /> :
              <Navigate to="/login" />} />
            <Route path="/interactions" element={isLoggedIn ?
              <Interactions
                  collections={interactionsCollectionsList}
                  setCollectionList={setInteractionsCollectionsList}
                  templates={interactionsTemplatesList}
                  setTemplateList={setInteractionsTemplateList}
                  conversations={interactionsConversationsList}
                  setConversations={setInteractionsConversationsList}
                  selectedCollectionKey={selectedInteractionsCollectionKey}
                  setSelectedCollectionKey={setSelectedInteractionsCollectionKey}
                  selectedCollection={selectedInteractionsCollection}
                  setSelectedCollection={setSelectedInteractionsCollection}
                  selectedTemplateKey={selectedInteractionsTemplateKey}
                  setSelectedTemplateKey={setSelectedInteractionsTemplateKey}
                  selectedTemplate={selectedInteractionsTemplate}
                  setSelectedTemplate={setSelectedInteractionsTemplate}
                  selectedConversationKey={selectedInteractionsConversationKey}
                  setSelectedConversationKey={setSelectedInteractionsConversationKey}
                  selectedConversation={selectedInteractionsConversation}
                  setSelectedConversation={setSelectedInteractionsConversation}
                  conversationHistory={conversationHistory}
                  setConversationHistory={setConversationHistory}
                  resultloading={resultloading}
                  setResultloading={setResultloading}
                  qaloading={qaloading}
                  setQaloading={setQaloading}
                  question={question}
                  setQuestion={setQuestion}
                  results={results}
                  setResults={setResults}
                  sources={sources}
                  setSources={setSources}
                  QASummary={QASummary}
                  setQASummary={setQASummary}
                  updatedTemperature={updatedTemperature}
                  setUpdatedTemperature={setUpdatedTemperature}
                  updatedModel={updatedModel}
                  setUpdatedModel={setUpdatedModel}
                  updatedK={updatedK}
                  setUpdatedK={setUpdatedK}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  drawerVisible={drawerVisible}
                  setDrawerVisible={setDrawerVisible}
                  promptTemplate={promptTemplate}
                  setPromptTemplate={setPromptTemplate}
                  inputValues={inputValues}
                  setInputValues={setInputValues}
                //   openSocket={openSocket}
                // setOpenSocket={setOpenSocket}
                  docSummaries={docSummaries} 
                  setDocSummaries={setDocSummaries}
              /> : <Navigate to="/login" />} />
          </Routes>
        </Router>
      </ConfigProvider>
    </div>
  );
}

const AppWithUserProvider = () => (
  <UserProvider>
    <App />
  </UserProvider>
);

export default AppWithUserProvider;