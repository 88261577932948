import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Tour, Tooltip, Progress, Typography, Collapse, Card, Space, Tabs, notification, Popconfirm, Input, Select, Pagination, Table } from 'antd';

import { UserContext } from '../UserContext';
import { EditOutlined, SaveOutlined, ReloadOutlined, LikeOutlined, CheckOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import axiosInstance from '../axiosInstance';
import { Column, Line, Rose, } from '@antv/g2plot'
// import WebsocketHandler from "../Websocket";
import API_ENDPOINTS from '../../apiConfig';
import styles from '../../styles/Reports.module.css';
import { parseChartData } from '../Interactions/Tabular'
import WordCloudComponent from '../WordCloudComponent';

const { TextArea } = Input;
const { Panel } = Collapse;

const Preview = ({
    setCurrentStep,
    selectedCollectionKey,
    selectedCollection,
    setSelectedCollectionKey,
    selectedTemplateKey,
    setSelectedTemplateKey,
    selectedReportKey,
    setSelectedReportKey,
    selectedReport,
    setSelectedReport,
    questions,
    setQuestions,
    setDefaultOrderQuestions,
    // openSocket,
    // setOpenSocket,
    docSummaries, setDocSummaries,
    ...otherProps
}) => {
    const { uid, token } = useContext(UserContext);
    // const [questions, setQuestions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 1;
    const [tempInteractionFlag, setTempInteractionFlag] = useState('');
    const [isPopconfirmVisible, setIsPopconfirmVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [loadingQuestionIndex, setLoadingQuestionIndex] = useState(0);
    const [currentQuestionIndex] = useState(null);
    const [reloadProgress, setReloadProgress] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [generatingResponsesFlag, setGeneratingFlag] = useState(false);
    const [openTour, setOpenTour] = useState(false);

    const queryRef = useRef(null);
    const responseRef = useRef(null);
    const sourcesRef = useRef(null);
    const editButtonRef = useRef(null);
    const regenerateButtonRef = useRef(null);
    const saveButtonRef = useRef(null);
    const [chartTitle, setChartTitle] = useState('');
    const [answers, setAnswers] = useState()

    const [tasks, setTasks] = useState([]);
    // const chartRef = useRef(null);
    const containerRef = useRef(null);
    const [pollQuestions, setPollQuestions] = useState(false);
    const [docSummariesTableColumns, setDocSummariesTableColumns] = useState([
        {
            title: "Page",
            dataIndex: "page_number",
            key: "page_number"
        },
        {
            title: "Source",
            dataIndex: "source_location",
            key: "source_location"
        },
        {
            title: "Passage",
            dataIndex: "passage",
            key: "passage",

        }
    ])

    // useEffect(() => {
    //   if (questions.length > 0 && questions.some(question => question.interaction_id === 'Tabular') && !chartRef.current) {

    //     renderChart();
    //   }
    // }, [currentIndex, questions]);

    const fetchQuestions = async () => {
        console.log("Fetching questions...")
        try {
            const url = `${API_ENDPOINTS.reports}/questions?user_id=${uid}&report_id=${selectedReportKey}`;
            setLoading(true);
            const response = await axiosInstance(token).get(url);
            const data = response.data.data;
            const parsedQuestions = data.questions.map((question, index) => {
                if (
                    question.question_id &&
                    question.interaction_id &&
                    question.header
                ) {
                    return {
                        ...question,
                        question_id: question.question_id,
                        question_detail: question.question_detail || '',
                        header: question.header,
                        interaction_id: question.interaction_id,
                        order: index + 1,
                        feedback: question.feedback,
                        response_detail: question.response_detail || '',
                        visual_path: question.visual_path
                            ? JSON.parse(question.visual_path)
                            : '',
                        qasources: question.qasources || {},
                        sumsources: question.sumsources || '',
                        formatted_response_path: question.formatted_response_path || '',
                        template_key: question.template_key || '',
                        collection_id: question.collection_id || null,
                        edit_flag: question.edit_flag || false,
                        status: question.status || '',
                        file_key: question.file_key || null,
                    }
                }
                return null;
            });
            const updatedQuestions = parsedQuestions.filter(Boolean)
            console.log("Updated questions: ", updatedQuestions)
            const fetchSummarySources = [...updatedQuestions].some(question => question.interaction_id === 'Summary')
            // if (fetchSummarySources)
            //     await fetchReviewSummariesJSON()
            setQuestions(updatedQuestions);
            if (parsedQuestions.length > 0) {
                setTempInteractionFlag(parsedQuestions[0].interaction_id);
                return updatedQuestions
                // await renderChart(); // Render the chart for the first question
            }
        } catch (error) {
            return [];
        }
        setLoading(false);
    };

    // const fetchReviewSummariesJSON = async () => {
    //     setLoading(true); // Set loading state to true
    //     setDocSummaries([])
    //     try {
    //         if (selectedCollectionKey === '') {
    //             notification.warning(
    //                 {
    //                     message: 'Missing collection',
    //                     description: 'Please select a collection to retrieve summaries on file.',
    //                 });
    //             return;
    //         }
    //         const response = await axiosInstance(token).get(`${API_ENDPOINTS.interactions}/summary2?user_id=${uid}&collection_id=${selectedCollectionKey}`);

    //         if (response.data.data.page_content.length === 0) {
    //             // notification.error('Failed to retrieve summaries on file. Please generate summaries.');
    //             return;
    //         }
    //         const sources = response.data.data.page_content.map(data => {
    //             return {
    //                 source_location: `${data?.metadata?.source?.split('/')[2]}` || '',
    //                 page_number: data?.metadata?.page || '',
    //                 passage: data?.page_content || ''
    //             } || undefined
    //         }).sort((a, b) => a.page_number - b.page_number)
    //         // Update the results area with the response data
    //         setDocSummaries(sources);
    //     } catch (error) {
    //         console.log(error);
    //         notification.error({
    //             message: 'Error',
    //             description: 'Failed to retrieve summaries on file. Please try again later.',
    //         });
    //     } finally {
    //         setLoading(false); // Set loading state to false after the request is completed
    //     }
    // };
    const toggleEdit = (index) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].edit_flag = !updatedQuestions[index].edit_flag;
        setQuestions(updatedQuestions);
        setDefaultOrderQuestions(updatedQuestions)
    };

    const handleInputChange = (index, field, value) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index] = {
            ...updatedQuestions[index],
            [field]: value,
        };
        setQuestions(updatedQuestions);
        setDefaultOrderQuestions(updatedQuestions)
    };

    const handleFeedbackChange = (index, feedback) => {
        handleInputChange(index, "feedback", !feedback);
        try {
            axiosInstance(token).patch(
                `${API_ENDPOINTS.reports}/feedback?report_id=${selectedReportKey}&question_id=${questions[index].question_id}&feedback=${!feedback}`
            );
            notification.success({ message: 'Interaction flag saved successfully' });
        }
        catch (error) {
            notification.error({ message: 'Error', description: 'Failed to save interaction flag' });
        }
    }

    const handleRunAllClick = async () => {
        if (!generatingResponsesFlag) {
            handleChangePage(1);
            setProgress(1)
            setQuestions(questions.map(question => { return { ...question, status: "pending...", response_detail: "" } }))
            await generateResponses();
        }
    };

    const handleChangePage = (page) => {
        setCurrentPage(page);
        setCurrentIndex((page - 1) * pageSize);
    };


    const start = (currentPage - 1) * pageSize;
    const end = (currentPage) * pageSize;

    // TODO: these funcs are not currently used
    //   const handleInteractionChange = (index, value) => {
    //     const updatedQuestions = [...questions];
    //     updatedQuestions[index].interaction_id = value;
    //     setQuestions(updatedQuestions);
    //     setDefaultOrderQuestions(updatedQuestions)
    //     saveQuestionInteractionFlag(index, value);
    //     setTempInteractionFlag(value);
    //   };

    //   const saveQuestionInteractionFlag = async (index, value) => {
    //     try {
    //       await axiosInstance(token).patch(
    //         `${API_ENDPOINTS.reports}/preview?user_id=${uid}&questionkey=${questions[index].questionid}&newflag=${encodeURIComponent(
    //           value
    //         )}`
    //       );
    //       notification.success({ message: 'Interaction flag saved successfully' });
    //     } catch (error) {
    //       notification.error({ message: 'Error', description: 'Failed to save interaction flag' });
    //     }
    //   };

    const fetchUpdatedQuestionData = async (question_id) => {
        //const question = questions[index];
        if (!question_id) {
            console.error('No question w. id:', question_id);
            return null;
        }
        try {
            const response = await axiosInstance(token).get(
                `${API_ENDPOINTS.reports}/preview?question_id=${question_id}&user_id=${uid}`
            );
            // notification.success({ message: 'Updated question data retrieved successfully' });
            return response.data.data;
        } catch (error) {
            notification.error({ message: 'Error', description: 'Failed to retrieve question data' });
            return null;
        }
    };


    useEffect(() => {
        if (reloadProgress === 100) {
            setTimeout(() => {
                setReloadProgress(0);
            }, 1000);
        }
    }, [reloadProgress]);

    useEffect(() => {
        if (pollQuestions && progress < 100) {
            setTimeout(() => {

            }, 5000);
            fetchQuestions().then((processingQuestions) => {
                if (processingQuestions?.length === 0) {
                    return;
                }
                const progress = ([...processingQuestions].filter(question => question.status === 'processed')).length
                const progressMeter = (100 / processingQuestions.length)
                setProgress((progress * progressMeter) > 0 ? (progress * progressMeter) : (progress * progressMeter) + 1)

                if ([...processingQuestions].every(question => question.status === 'processed')) {
                    setPollQuestions(false);
                    setProgress(100)
                    return
                }
                else {
                    setPollQuestions(true)
                    return
                }

            })
        }
        else if (progress === 100) {
            notification.success({ message: "Finished generating report." });
            setProgress(101)
        }
        else
            return
    }, [pollQuestions, questions, progress])


    const regenerateQuestion = (index) => {
        const question = questions[index];
        setReloadProgress(2);
        try {
            const response = axiosInstance(token).patch(`${API_ENDPOINTS.reports}/preview?user_id=${uid}&report_id=${selectedReportKey}`, question);
        }
        catch (error) {
            setReloadProgress(100);
            notification.error({ message: 'Error', description: 'Failed to retrieve question data' });
            return null;
        }
    }

    const reloadQuestion = async (question_id, index = null) => {
        const updatedQuestionData = await fetchUpdatedQuestionData(question_id);
        if (updatedQuestionData) {
            if (index == null) {
                index = questions.findIndex(question => question["question_id"] === question_id);
            }
            const updatedQuestions = (oldQuestions) => {
                const newQuestions = [...oldQuestions];
                //keys have to be adapted! front-end needs refactoring lol
                newQuestions[index]["response_detail"] = updatedQuestionData["data"]["response_detail"];
                newQuestions[index]["interaction_id"] = updatedQuestionData["data"]["interaction_id"];
                newQuestions[index]["qasources"] = updatedQuestionData["data"]["qasources"];
                newQuestions[index]["status"] = updatedQuestionData["data"]["status"];
                newQuestions[index]["edit_flag"] = false; //by default new loaded question to be in view mode
                return newQuestions;
            }
            setQuestions(updatedQuestions);
            setDefaultOrderQuestions(updatedQuestions)
            //setCurrentIndex(index);
        }
    };

    // }, [currentIndex]);

    useEffect(() => {
        fetchQuestions();
    }, [uid, selectedReportKey]);

    // const renderChart = async () => {
    //   const question = questions?.[currentIndex];
    //   if (!question) {
    //     await fetchQuestions();
    //   }
    //   try {
    //     const data = JSON.parse(question?.response_detail)
    //     const parsed = { ...data }
    //     const parsedChartData = parseChartData(parsed)
    //     const charts = {
    //       data: parsedChartData
    //         .filter(chart => !chart.context.type.includes('answer'))
    //         .map(chart => chart.data)
    //         .flat(Infinity)
    //         .sort((a, b) => a.x - b.x),
    //       context: parsedChartData
    //         .filter(chart => !chart.context.type.includes('answer'))
    //         .map(chart => chart.context)[0]
    //     }
    //     const answers = {
    //       data:
    //         parsedChartData
    //           .filter(chart => chart.context.type.includes('answer'))
    //           .map(chart => chart.data.answer)
    //           .flat(Infinity),
    //       context: parsedChartData
    //         .filter(chart => chart.context.type.includes('answer'))
    //         .map(chart => chart.context)[0]

    //     }
    //     setAnswers(answers);
    //     if (containerRef.current && charts?.context?.type) {
    //       let chart;
    //       switch (charts.context.type) {
    //         case 'line':
    //           chart = new Line(containerRef.current, {
    //             data: charts.data,
    //             autoFit: true,
    //             padding: 'auto',
    //             xField: 'x',
    //             yField: 'y',
    //             seriesField: 'type',
    //             smooth: true,
    //             area: {
    //               style: {
    //                 fillOpacity: 0.15,
    //               },
    //             },
    //             animation: {
    //               appear: {
    //                 animation: 'wave-in',
    //               },
    //             },
    //           });
    //           break;
    //         case 'pie':
    //           chart = new Rose(containerRef.current, {
    //             data: charts.data,
    //             xField: 'x',
    //             yField: 'y',
    //             isStack: true,
    //             autoFit: true,
    //             seriesField: 'type',
    //             radius: 0.9,
    //             label: {
    //               offset: '-15%',
    //               style: {
    //                 fontSize: 10,
    //                 textAlign: 'center',
    //                 fill: '#000'
    //               },
    //             },
    //             interactions: [{ type: 'element-active' }],
    //           });
    //           break;
    //         default:
    //           chart = new Column(containerRef.current, {
    //             data: charts.data,
    //             isStack: true,
    //             autoFit: true,
    //             xField: 'x',
    //             yField: 'y',
    //             seriesField: 'type',
    //             smooth: true,
    //             area: {
    //               style: {
    //                 fillOpacity: 0.15,
    //               },
    //             },
    //             animation: {
    //               appear: {
    //                 animation: 'wave-in',
    //               },
    //             },
    //           });
    //       }

    //       if (chart) {

    //         setChartTitle(charts.data?.length > 0 ?
    //           `${charts?.context?.meta?.chart_title}`.toLocaleUpperCase()
    //           : '')
    //         chart.render();
    //         // chartRef.current = chart;
    //         containerRef.current.focus();
    //       }
    //     }
    //   } catch (e) {
    //     console.log('ERROR ON RENDER CHART', e)
    //   }
    // };

    // WebsocketHandler( {
    //    openSocket,
    //   setOpenSocket,
    //   token: token,
    //   onMessage: async (message) => {
    //     console.log("Received message:", message);
    //     switch(message.type) {
    //       case "progress_bar":
    //         setProgress(message.data.progress);
    //         break;
    //       case "notification":
    //         switch(message.data.type){
    //           case "error":
    //             notification.error({description:message.data.text});
    //             break;
    //           case "warning":
    //             notification.warning({description:message.data.text});
    //             break;
    //           //one can add the other notification types here, but it is currently not neccessary
    //           default:
    //             break;
    //         }
    //         break;
    //       case "celery_task":
    //         notification.success({message: `${message.data.id} is ${message.data.status}`});
    //         if(message.data.status === "processed"){
    //           await reloadQuestion(message.data.id);
    //           setReloadProgress(100);
    //           setOpenSocket(false);
    //         }
    //         break;
    //       default:
    //         console.log('unrecognized type of websocket message!');
    //         break;
    //     }
    //   }
    // });

    const tourSteps = [
        {
            title: 'Query',
            description: 'The query passed to the transformer.',
            target: () => queryRef.current,
        },
        {
            title: 'Response',
            description: 'The generated response.',
            target: () => responseRef.current,
        },
        {
            title: 'Sources',
            description: 'Sources for the generated response.',
            target: () => sourcesRef.current,
        },
        {
            title: 'Edit button',
            description: 'Edit the query and response fields.',
            target: () => editButtonRef.current,
        },
        {
            title: 'Regenerate response',
            description: 'Regenerate the response with any edits.',
            target: () => regenerateButtonRef.current,
        },
        {
            title: 'Save edits',
            description: 'Save any edits without generating a response.',
            target: () => saveButtonRef.current,
        }
    ]

    const questionColumns = [
        {
            title: "Header",
            dataIndex: "header",
            key: "header",
            width: 200
        },
        {
            title: "Question",
            dataIndex: "question_detail",
            key: "question_detail",
            width: 300
        },
        {
            title: "Response",
            dataIndex: "response_detail",
            key: "response_detail",
            render: (text, record) => {
                if (record.interaction_id === 'Tabular CSV') {
                    let responseObj;
                    try {
                        responseObj = JSON.parse(record.response_detail);
                    } catch (error) {
                        console.error("Error parsing record:", error);
                        return <div></div>;
                    }
                    return (
                        <>
                            <div>{responseObj.response}</div>
                            {responseObj.excel_s3_url && (
                                <a href={responseObj.excel_s3_url} download>Download Excel</a>
                            )}
                            {responseObj.png_s3_url && (
                                <div>
                                    <br></br>
                                    <img
                                        src={responseObj.png_s3_url}
                                        alt="Data Visualization"
                                        style={{ maxWidth: '600px' }}
                                    />
                                </div>
                            )}
                        </>
                    );
                } else if (record.interaction_id === 'Comment Parser') {
                    let parsedData;
                    try {
                        parsedData = JSON.parse(record.response_detail);
                    } catch (error) {
                        console.error("Error parsing comment data:", error);
                        return <div></div>;
                    }
                    return <div>test</div>
                    // return <WordCloudComponent commentResult={parsedData.response} />;
                } else if (record.interaction_id === 'Lead Generator') {
                    let parsedData;
                    try {
                        parsedData = JSON.parse(record.response_detail);
                    } catch (error) {
                        console.error("Error parsing lead data:", error);
                        return <div></div>;
                    }
                    return <div style={{ whiteSpace: 'pre-wrap' }}>{parsedData.response}</div>;
                }
                else {
                    // Default case for other interaction_ids
                    return <div>{text}</div>;
                }
            }
        },
        {
            title: "Agent Type",
            dataIndex: "interaction_id",
            key: "interaction_id",
            width: 100
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width: 100
        }
    ]

    /*
      This function posts the question data to postgres w/out generating a response.
    */
    const updateQuestion = async (question) => {
        axiosInstance(token).post(`${API_ENDPOINTS.reports}/update?report_id=${selectedReportKey}`, question)
            .then((response) => {
                notification.success({ message: "Saved updated question to server." });
                question.edit_flag = false;
            })
            .catch((error) => {
                notification.error({ message: 'Error', description: 'Failed to save to server.' });
            })
    }

    const generateResponses = async () => {
        setGeneratingFlag(true);
        axiosInstance(token)
            .post(`${API_ENDPOINTS.reports}/generate_responses?user_id=${uid}&report_id=${selectedReportKey}`)
            .then((response) => {
                setPollQuestions(true);
                //give server time to properly disconnect ws before allowing user to generate again
                setTimeout(() => {
                    setGeneratingFlag(false);
                }, 3000);
            })
            .catch((error) => {
                setGeneratingFlag(false);
                setProgress(100);
                notification.error({ message: 'Error', description: 'Failed to generate report.' });
            }).finally(async () => {
                await fetchQuestions();
                // setOpenSocket( false );
            })
    }


    return (
        <div>
            <Card
                title={
                    selectedReport
                        ? `Preview Responses for ${selectedReport}`
                        : 'Please select a report on Step 1!'
                }
                className="custom-card"
                hoverable
                extra={[
                    <Space size="small" direction="horizontal">
                        <Button type="primary" size="middle" onClick={handleRunAllClick}>
                            Run All
                        </Button>
                    </Space>
                ]}
            >
                {progress > 0 && progress < 100 && (
                    <>
                        <Progress percent={progress} status="active" format={(percent) => `${Math.floor(percent)}%`} />
                        <p>
                            <Typography.Text>Loading question {currentQuestionIndex} / {questions.length}</Typography.Text>
                        </p>
                    </>
                )}
                <Tabs defaultActiveKey="table" >
                    <Tabs.TabPane tab="Question Table" key="table">
                        <Card key="data_table" title="Question table">
                            <Table
                                dataSource={questions}
                                columns={questionColumns}
                                tableLayout='fixed'
                            ></Table>
                        </Card>
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        // onClick={async()=>renderChart()}
                        tab="Details" key="details">
                        {questions.slice(start, end).map(question => {
                            var index = start;
                            return (
                                <>
                                    <Card
                                        key={question.question_id}
                                        title={`Question ${question.order}: ${question.header} `}
                                        extra={
                                            <>
                                                <Popconfirm
                                                    title="Are you sure you want to reload interaction?"
                                                    onConfirm={async () => {
                                                        setIsPopconfirmVisible(false);
                                                        await regenerateQuestion(currentIndex);
                                                    }}
                                                    onCancel={() => {
                                                        handleInputChange(index, "interaction_id", tempInteractionFlag);
                                                        setIsPopconfirmVisible(false);
                                                    }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                    open={isPopconfirmVisible}
                                                >
                                                    <Tooltip title="Edit">
                                                        <Button icon={<EditOutlined />} ref={editButtonRef} onClick={() => toggleEdit(index)} />
                                                    </Tooltip>
                                                    <Tooltip title="Regenerate response">
                                                        <Button
                                                            icon={<ReloadOutlined />}
                                                            onClick={async () => {
                                                                if (question.edit_flag) {
                                                                    setIsPopconfirmVisible(true);
                                                                }
                                                            }}
                                                            ref={regenerateButtonRef}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Save edit">
                                                        <Button icon={<SaveOutlined />} ref={saveButtonRef} onClick={() => {
                                                            if (question.edit_flag) {
                                                                updateQuestion(question);
                                                            }
                                                        }}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Help">
                                                        <Button icon={<QuestionCircleOutlined />} onClick={() => {
                                                            setOpenTour(true);
                                                        }}
                                                        />
                                                    </Tooltip>
                                                    <span> </span>
                                                    <Select
                                                        showSearch
                                                        optionFilterProp="children"
                                                        style={{ width: 120 }}
                                                        value={question.interaction_id}
                                                        onChange={(value) => {
                                                            handleInputChange(index, "interaction_id", value); //assign to question first to avoid async problems w/ state
                                                            setTempInteractionFlag(question.interaction_id);
                                                            setIsPopconfirmVisible(true);
                                                        }}
                                                    >
                                                        <Select.Option value="agent">Agent</Select.Option>
                                                        <Select.Option value="Q&A">Q&A</Select.Option>
                                                        <Select.Option value="Summary">Summary</Select.Option>
                                                        <Select.Option value="Tabular">Tabular</Select.Option>
                                                        <Select.Option value="Tabular CSV">Tabular CSV</Select.Option>
                                                        <Select.Option value="Lead Generator">Lead Generator</Select.Option>
                                                        <Select.Option value="Comment Parser">Comment Parser</Select.Option>
                                                    </Select>
                                                    <span> </span>
                                                    <Button
                                                        icon={question.feedback ? <CheckOutlined /> : <LikeOutlined />}
                                                        onClick={() => handleFeedbackChange(index, question.feedback)}
                                                    ></Button>
                                                </Popconfirm>
                                            </>
                                        }
                                    >
                                        {(question.interaction_flag === 'Q&A' || question.interaction_id === 'Q&A') ? (
                                            <>
                                                {reloadProgress > 0 && (
                                                    <Progress percent={reloadProgress} status="active" format={(percent) => `${Math.floor(percent)}%`} />
                                                )}
                                                <p ref={queryRef}>
                                                    <strong>Query: </strong>
                                                    <TextArea
                                                        value={question.question_detail}
                                                        onChange={(e) => handleInputChange(index, 'question_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>
                                                <p ref={responseRef}>
                                                    <strong>Response: </strong>
                                                    <TextArea
                                                        value={question.response_detail}
                                                        onChange={(e) => handleInputChange(index, 'response_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>
                                                <Collapse size="small">
                                                    <Panel header="Q&A sources" ref={sourcesRef}>
                                                        {question.qasources && (
                                                            <ul>
                                                                {Object.entries(question.qasources).map(([key, value], i) => (
                                                                    <li key={i}>
                                                                        {key}: {value}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                        {question.visual_path?.length > 0 && (
                                                            <img src={question.visual_path} alt="Visual" />
                                                        )}
                                                    </Panel>
                                                </Collapse>
                                            </>
                                        ) : question.interaction_id === 'Summary' ? (
                                            <>
                                                {reloadProgress > 0 && (
                                                    <Progress percent={reloadProgress} status="active" format={(percent) => `${Math.floor(percent)}%`} />
                                                )}
                                                <p ref={queryRef}>
                                                    <strong>Query: </strong>
                                                    <TextArea
                                                        value={question.question_detail}
                                                        onChange={(e) => handleInputChange(index, 'question_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>
                                                <p ref={responseRef}>
                                                    <strong>Summary: </strong>
                                                    <TextArea
                                                        value={question.response_detail}
                                                        onChange={(e) => handleInputChange(index, 'response_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>
                                                <Collapse size="small">
                                                    <Panel header="Summary sources" ref={sourcesRef}>


                                                        <Card align="left" title="Sources table" >

                                                            <Table dataSource={docSummaries} columns={docSummariesTableColumns}></Table>
                                                        </Card>
                                                    </Panel>
                                                </Collapse>
                                            </>
                                        ) : question.interaction_id === 'Tabular' ? (
                                            <>
                                                {reloadProgress > 0 && (
                                                    <Progress percent={reloadProgress} status="active" format={(percent) => `${Math.floor(percent)}%`} />
                                                )}
                                                <p ref={queryRef}>
                                                    <strong>Query: </strong>
                                                    <TextArea
                                                        value={!!question.question_detail ? question.question_detail : ''}
                                                        onChange={(e) => handleInputChange(index, 'question_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>
                                                <div>
                                                    {question.response_detail?.length > 0 && (
                                                        <p ref={responseRef}>
                                                            <strong>Response: </strong>
                                                            <TextArea
                                                                value={!question.edit_flag && answers?.data.length > 0 ? answers?.data.join('\n\n') : question.response_detail}
                                                                onChange={(e) => handleInputChange(index, 'response_detail', e.target.value)}
                                                                disabled={!question.edit_flag}
                                                                className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                            />
                                                        </p>
                                                    )}
                                                </div>
                                                <div>
                                                    {/* <Card title="Visual" hoverable align="left">
                    <div className={styles.chartContainer} ref={containerRef}></div>
                    </Card> */}
                                                    {(
                                                        <Card
                                                            title={chartTitle}
                                                            hoverable
                                                            align="center"
                                                        >
                                                            <div ref={containerRef}>
                                                            </div>
                                                        </Card>
                                                    )}
                                                </div>
                                            </>
                                        ) : question.interaction_id === 'Tabular CSV' ? (
                                            <>
                                                {reloadProgress > 0 && (
                                                    <Progress percent={reloadProgress} status="active" format={(percent) => `${Math.floor(percent)}%`} />
                                                )}
                                                <p ref={queryRef}>
                                                    <strong>Query: </strong>
                                                    <TextArea
                                                        value={!!question.question_detail ? question.question_detail : ''}
                                                        onChange={(e) => handleInputChange(index, 'question_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>
                                                <>
                                                    <div>
                                                        <p ref={responseRef}>
                                                            <strong>Response: </strong>
                                                            <TextArea
                                                                value={
                                                                    !question.edit_flag && answers?.data.length > 0
                                                                        ? answers?.data.join('\n\n')
                                                                        : (function () {
                                                                            try {
                                                                                const parsedResponse = JSON.parse(question.response_detail);
                                                                                return parsedResponse.response || question.response_detail;
                                                                            } catch (error) {
                                                                                console.error("Error parsing response_detail:", error);
                                                                                return "Error in response format";
                                                                            }
                                                                        })()
                                                                }
                                                                onChange={(e) => handleInputChange(index, 'response_detail', e.target.value)}
                                                                disabled={!question.edit_flag}
                                                                className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                            />
                                                        </p>
                                                    </div>

                                                    <div style={{ display: 'flex', justifyContent: 'center' }}> {/* Container with flex display */}
                                                        {(() => {
                                                            try {
                                                                const parsedDetail = JSON.parse(question.response_detail);
                                                                return (
                                                                    <>
                                                                        {parsedDetail.excel_s3_url && (
                                                                            <a href={parsedDetail.excel_s3_url} download>Download Excel</a>
                                                                        )}
                                                                        {parsedDetail.png_s3_url && (
                                                                            <img
                                                                                src={parsedDetail.png_s3_url}
                                                                                alt="Data Visualization"
                                                                                style={{ maxWidth: '600px', display: 'block' }} // Image with max width
                                                                            />
                                                                        )}
                                                                    </>
                                                                );
                                                            } catch (error) {
                                                                console.error("Error parsing question.response_detail:", error);
                                                                return null; // Or handle this case as you see fit
                                                            }
                                                        })()}
                                                    </div>
                                                </>
                                            </>
                                        ) : question.interaction_id === 'Lead Generator' ? (
                                            <>
                                                {reloadProgress > 0 && (
                                                    <Progress percent={reloadProgress} status="active" format={(percent) => `${Math.floor(percent)}%`} />
                                                )}
                                                <p ref={queryRef}>
                                                    <strong>Query: </strong>
                                                    <TextArea
                                                        value={!!question.question_detail ? question.question_detail : ''}
                                                        onChange={(e) => handleInputChange(index, 'question_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>
                                                <p ref={responseRef}>
                                                    <strong>Response: </strong>
                                                    <TextArea
                                                        value={
                                                            !question.edit_flag && answers?.data.length > 0
                                                                ? answers?.data.join('\n\n')
                                                                : (() => {  // Use an IIFE to handle the parsing and fallback
                                                                    try {
                                                                        const parsedDetail = JSON.parse(question.response_detail);
                                                                        return parsedDetail.response || question.response_detail; // Use parsedDetail.response if available
                                                                    } catch (error) {
                                                                        console.error("Error parsing question.response_detail:", error);
                                                                        return "Error in response format"; // Fallback message in case of parsing error
                                                                    }
                                                                })()
                                                        }
                                                        onChange={(e) => handleInputChange(index, 'response_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>

                                            </>
                                        ) : question.interaction_id === 'Comment Parser' ? (
                                            <>
                                                {reloadProgress > 0 && (
                                                    <Progress percent={reloadProgress} status="active" format={(percent) => `${Math.floor(percent)}%`} />
                                                )}
                                                <p ref={queryRef}>
                                                    <strong>Query: </strong>
                                                    <TextArea
                                                        value={!!question.question_detail ? question.question_detail : ''}
                                                        onChange={(e) => handleInputChange(index, 'question_detail', e.target.value)}
                                                        disabled={!question.edit_flag}
                                                        className={`${styles.textArea} ${!question.edit_flag ? styles.textAreaLocked : ''}`}
                                                    />
                                                </p>
                                                <div>
                                                    <p ref={responseRef}>
                                                        <strong>Response: </strong>
                                                        {(() => {
                                                            try {
                                                                const parsedData = JSON.parse(question.response_detail);
                                                                return <WordCloudComponent commentResult={parsedData.response} />;
                                                            } catch (error) {
                                                                console.error("Error parsing comment data:", error);
                                                                return <div>Error in comment data format</div>;
                                                            }
                                                        })()}
                                                    </p>
                                                </div>
                                            </>
                                        ) : ( // Default case for other interaction_ids
                                            <>
                                            </>
                                        )
                                        }
                                    </Card>
                                </>
                            )
                        })
                        }

                        <Tour open={openTour} onClose={() => setOpenTour(false)} steps={tourSteps} />
                        <Pagination
                            current={currentPage}
                            onChange={handleChangePage}
                            total={questions.length}
                            pageSize={pageSize}
                            showSizeChanger={false}
                            showQuickJumper
                        />
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </div>
    );
};

export default Preview;
