// UserContext.js
import React, { createContext, useState, useEffect } from 'react';
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [uid, setUid] = useState('');
  const [token, setToken] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [openSocket, setOpenSocket] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUid('');
    setToken('');
  };

  const handleToken = (token) => {
    setToken(token);
  };

   // REPORTS STATE MANAGEMENT
  const [currentStep, setCurrentStep] = useState(0);

  const [reportsCollectionsList, setReportsCollectionList] = useState([]);
  const [reports, setReportList] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);
  const [selectedReportKey, setSelectedReportKey] = useState('');

  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedReportsCollectionKey, setSelectedReportsCollectionKey] = useState('');
  const [selectedCollection, setSelectedCollection ] = useState( null );
  
  // COLLECTIONS STATE MAMANGEMENT
  const [collectionsList, setCollectionsList] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [currentCollectionName, setCurrentCollectionName] = useState('');
  const [selectedCollectionKey, setSelectedCollectionKey] = useState(
    collectionsList.length > 0 ? collectionsList[0].key : ''
  );
  const [ docSummaries, setDocSummaries ] = useState( [] );
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chunkSize, setChunkSize] = useState('');
  const [ overlap, setOverlap ] = useState( '' );
  
  // TEMPLATES STATE MANAGEMENT
  const [templates, setTemplates] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [selectedTemplateKey, setSelectedTemplateKey] = useState('');
  const [updatePromptVariables, setUpdatePromptVariables] = useState('');
  const [updatePromptTemplate, setUpdatePromptTemplate] = useState('');
  const [selectedTemplateLabel, setSelectedTemplateLabel] = useState('');
  
  // TEMPLATES (REPORT) STATE MANAGEMENT
  const [reportTemplates, setReportTemplates] = useState([]);
  const [selectedReportsTemplateKey, setSelectedReportsTemplateKey] = useState('');


  //INTERACTIONS STATE MANAGEMENT
       const [interactionsCollectionsList, setInteractionsCollectionsList] = useState([]);
    const [interactionsTemplatesList, setInteractionsTemplateList] = useState([]);
    const [interactionsConversationsList, setInteractionsConversationsList] = useState([]);

    const [selectedInteractionsCollectionKey, setSelectedInteractionsCollectionKey] = useState(interactionsCollectionsList.length > 0 ? interactionsCollectionsList[0].key : '');
    const [selectedInteractionsCollection, setSelectedInteractionsCollection] = useState(null);
  
    const [selectedInteractionsTemplateKey, setSelectedInteractionsTemplateKey] = useState(templates.length > 0 ? templates[0].key : '');
    
    const [selectedInteractionsTemplate, setSelectedInteractionsTemplate] = useState(null);
    const [selectedInteractionsConversationKey, setSelectedInteractionsConversationKey] = useState(interactionsConversationsList.length > 0 ? interactionsConversationsList[0].key : '');
    const [selectedInteractionsConversation, setSelectedInteractionsConversation] = useState(null);
    const [conversationHistory, setConversationHistory] = useState([]);

    // need to remove unnecessary states
    const [resultloading, setResultloading] = useState(false);
    const [qaloading, setQaloading] = useState(false);
    const [question, setQuestion] = useState('');
    const [results, setResults] = useState([]);
    const [sources, setSources] = useState([]);
    const [QASummary, setQASummary] = useState('');

    const [updatedTemperature, setUpdatedTemperature] = useState('');
    const [updatedModel, setUpdatedModel] = useState('');
    const [updatedK, setUpdatedK] = useState('');

    const [selectedTab, setSelectedTab] = useState('1');
    const [drawerVisible, setDrawerVisible] = useState(false);

    const [promptTemplate, setPromptTemplate] = useState({});
    const [inputValues, setInputValues] = useState({});
  
  useEffect( () =>
  { 
    if ( !isLoggedIn )
    {
      // REPORTS STATE MANAGEMENT
      setCurrentStep(0);
      setReportsCollectionList( [] );
      setReportList( [] );
      setReportLoading( false );
      setSelectedReportKey( '' );
      setSelectedReport( null );
      setSelectedReportsCollectionKey( '' );
      setSelectedCollection( null );
  
      // COLLECTIONS STATE MANAGEMENT
      setCollectionsList( [] );
      setUploadedFiles( [] );
      setCurrentCollectionName( '' );
      setSelectedCollectionKey(
    collectionsList.length > 0 ? collectionsList[0].key : ''
      );
      setIsLoading( false );
      setLoading( true );
      setChunkSize( '' );
      setOverlap( '' );
      setDocSummaries( [] );
      // TEMPLATES STATE MANAGEMENT
      setTemplates( [] );
      setJsonData( [] );
      setSelectedTemplateKey( '' );
      setUpdatePromptVariables( '' );
      setUpdatePromptTemplate( '' );
      setSelectedTemplateLabel( '' );
  
      // TEMPLATES (REPORT) STATE MANAGEMENT
      setReportTemplates( [] );
      setSelectedReportsTemplateKey( '' );


      //INTERACTIONS STATE MANAGEMENT
      setInteractionsCollectionsList( [] );
      setInteractionsTemplateList( [] );
      setInteractionsConversationsList( [] );
      setSelectedInteractionsCollectionKey( interactionsCollectionsList.length > 0 ? interactionsCollectionsList[ 0 ].key : '' );
      setSelectedInteractionsCollection( null );
      setSelectedInteractionsTemplateKey( templates.length > 0 ? templates[ 0 ].key : '' );
      setSelectedInteractionsTemplate( null );
      setSelectedInteractionsConversationKey( interactionsConversationsList.length > 0 ? interactionsConversationsList[ 0 ].key : '' );
      setSelectedInteractionsConversation( null );
      setConversationHistory( [] );

      // need to remove unnecessary states
      setResultloading( false );
      setQaloading( false );
      setQuestion( '' );
      setResults( [] );
      setSources( [] );
      setQASummary( '' );
      setUpdatedTemperature( '' );
      setUpdatedModel( '' );
      setUpdatedK( '' );
      setSelectedTab( '1' );
      setDrawerVisible( false );
      setPromptTemplate( {} );
      setInputValues( {} );
    }
  }, [isLoggedIn])

  return (
    <UserContext.Provider
      value={{ uid, setUid, token, setToken, isLoggedIn, handleLogin, handleLogout, handleToken, currentStep, setCurrentStep,
reportsCollectionsList, setReportsCollectionList,
reports, setReportList,
reportLoading, setReportLoading,
selectedReportKey, setSelectedReportKey,
selectedReport, setSelectedReport,
selectedReportsCollectionKey, setSelectedReportsCollectionKey,
selectedCollection, setSelectedCollection,
collectionsList, setCollectionsList,
uploadedFiles, setUploadedFiles,
currentCollectionName, setCurrentCollectionName,
selectedCollectionKey, setSelectedCollectionKey,
isLoading, setIsLoading,
loading, setLoading,
chunkSize, setChunkSize,
overlap, setOverlap,
templates, setTemplates,
jsonData, setJsonData,
selectedTemplateKey, setSelectedTemplateKey,
updatePromptVariables, setUpdatePromptVariables,
updatePromptTemplate, setUpdatePromptTemplate,
selectedTemplateLabel, setSelectedTemplateLabel,
reportTemplates, setReportTemplates,
selectedReportsTemplateKey, setSelectedReportsTemplateKey,
interactionsCollectionsList, setInteractionsCollectionsList,
interactionsTemplatesList, setInteractionsTemplateList,
interactionsConversationsList, setInteractionsConversationsList,
selectedInteractionsCollectionKey, setSelectedInteractionsCollectionKey,
selectedInteractionsCollection, setSelectedInteractionsCollection,
selectedInteractionsTemplateKey, setSelectedInteractionsTemplateKey,
selectedInteractionsTemplate, setSelectedInteractionsTemplate,
selectedInteractionsConversationKey, setSelectedInteractionsConversationKey,
selectedInteractionsConversation, setSelectedInteractionsConversation,
conversationHistory, setConversationHistory,
resultloading, setResultloading,
qaloading, setQaloading,
question, setQuestion,
results, setResults,
sources, setSources,
QASummary, setQASummary,
updatedTemperature, setUpdatedTemperature,
updatedModel, setUpdatedModel,
updatedK, setUpdatedK,
selectedTab, setSelectedTab,
drawerVisible, setDrawerVisible,
promptTemplate, setPromptTemplate,
inputValues, setInputValues,
        // openSocket, setOpenSocket,
        docSummaries, setDocSummaries,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
