import React, { useState, useEffect, useContext } from 'react';
import { Spin, Card, Space, message, notification, Row, Input, Select, Button } from 'antd';
import { UserContext } from '../UserContext';
import axiosInstance from '../axiosInstance';
import API_ENDPOINTS from '../../apiConfig';
import ReactMarkdown from "react-markdown";

const TabularCSV = ({
    selectedCollectionKey,
    selectedCollection,
    ...otherProps }) => {

    const { Option } = Select;
    const { TextArea } = Input;

    const { uid, token } = useContext(UserContext)
    const [resultLoading, setResultLoading] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [question, setQuestion] = useState('');
    const [results, setResults] = useState('');
    const [excelS3Url, setExcelS3Url] = useState('');
    const [pngS3Url, setPngS3Url] = useState('');

    useEffect(() => {
        setFileList([]);
        setSelectedFileName('');
        fetchFilesList(selectedCollectionKey);
    }, [selectedCollectionKey]);  // fetch files list when selectedCollectionKey changes

    const fetchFilesList = (key) => {
        if (key === undefined || key === '') {
            console.log('Key is undefined. Axios request not made.');
            return;
        }

        axiosInstance(token)
            .get(`${API_ENDPOINTS.collectionsUpload}?user_id=${uid}&collection_id=${key}`)
            .then((response) => {
                const fileNames = response.data.data.map((file) => file.file_name);
                setFileList(fileNames);
            })
            .catch((error) => {
                console.log(error);
                if (error.response) {
                    notification.error({
                        message: 'Error',
                        description: 'Failed to pull list of collection. Please try again later.',
                    });
                }
            });
    };


    const sendQuestion = async (event) => {
        if (!selectedFileName) {
            message.warning('Please select a file!');
        }
        else {
            setResultLoading(true); // Set loading state to true
            setExcelS3Url('');
            setPngS3Url('');
            setResults('');
            try {
                let file_key = uid + "/" + selectedCollectionKey + "/" + selectedFileName;
                let url = `${API_ENDPOINTS.interactions}/tabular_csv?user_id=${uid}&collection_id=${selectedCollectionKey}&file_key=${file_key}`;
                const response = await axiosInstance(token).post(url, {
                    query: question,
                });
                setResults(response.data.response);
                if (response.data.excel_s3_url) {
                    setExcelS3Url(response.data.excel_s3_url);
                }
                if (response.data.png_s3_url) {
                    setPngS3Url(response.data.png_s3_url);
                }
                console.log(response.data)
            } catch (error) {
                console.log(error);

                // Display error notification to the user
                notification.error({
                    message: 'Error',
                    description: 'Failed to send question. Please try again later.',
                });
            } finally {
                setResultLoading(false); // Set loading state to false after the request is completed
            }
        }
    };
    const downloadExcel = () => {
        if (excelS3Url) {
            window.open(excelS3Url, '_blank');
        }
    };
    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card
                hoverable
                size="small"
                align="left"
                title="Select file"
            >
                <div>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        placeholder="Select a file"
                        value={selectedFileName}
                        onChange={(value) => setSelectedFileName(value)}
                    >
                        {fileList.map((filename, index) => (
                            <Option key={index} value={filename}>{filename}</Option>
                        ))}
                    </Select>
                </div>
            </Card>
            <Card>
                <Row>
                    <TextArea
                        placeholder="Write your question here..."
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                    />
                </Row>
                <Row justify='end' style={{ marginTop: '10px' }}>
                    <Button
                        type="primary"
                        onClick={sendQuestion}
                        loading={resultLoading}
                        disabled={resultLoading || !question.trim()}>
                        {resultLoading ? 'Sending...' : 'Send'}
                    </Button>
                </Row>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', marginTop: '20px' }}>
                    <Spin spinning={resultLoading} ></Spin>
                </div>
                <div>
                    {results && (
                        <Card className="custom-card" title="Results" align="left">
                            <ReactMarkdown>{results}</ReactMarkdown>
                            {excelS3Url && (
                                <Button type="primary" onClick={downloadExcel} style={{ marginLeft: '10px' }}>
                                    Download
                                </Button>
                            )}
                            <br></br>
                            {pngS3Url && (
                                <img src={pngS3Url} alt="Chart" style={{ marginTop: '10px' }} />
                            )}
                        </Card>
                    )}
                </div>
            </Card>
        </Space >
    )
}

export default TabularCSV;
