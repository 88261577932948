import API_ENDPOINTS from '../../apiConfig';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Button, message, Select, Card, Typography, Input, Tabs, Row, Col, Popconfirm, Space } from 'antd';
import axiosInstance from '../axiosInstance';
import { UserContext } from '../UserContext';
import ReportTemplate from "../Templates/ReportTemplate";

import styles from '../../styles/Collections.module.css';
import 'antd/dist/reset.css';

const { Option } = Select;
const { TextArea } = Input;

const PromptTemplate = ({
    templates,
    setTemplates,
    jsonData,
    setJsonData,
    selectedTemplateKey,
    setSelectedTemplateKey,
    updatePromptVariables,
    setUpdatePromptVariables,
    updatePromptTemplate,
    setUpdatePromptTemplate,
    selectedTemplateLabel,
    setSelectedTemplateLabel
}) => {
    const { uid, token } = useContext(UserContext);

    const fetchTemplates = useCallback(() => {
        axiosInstance(token)
            .get(`${API_ENDPOINTS.templates}?user_id=${uid}`)
            .then((response) => {
                setTemplates(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, uid]);

    const createTemplate = () => {
        const nsname = prompt('Enter a name for the new template:');
        if (nsname) {
            const dataToSend = {
                template_name: nsname,
                user_id: uid
            };
            axiosInstance(token)
                .post(API_ENDPOINTS.templates, dataToSend)
                .then((response) => {
                    console.log('Template created ', response.data);
                    fetchTemplates();
                    setSelectedTemplateKey(response.data.template_key);
                    message.success('Template created');
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response.data.message);
                    message.error('Template not created');
                });
        }
    };

    const renameTemplate = () => {
        const templateKey = selectedTemplateKey;
        const label = templates.find((template) => template.key === templateKey)?.label;
        const newLabel = prompt(`Enter a new name for ${label}:`);
        if (newLabel) {
            const dataToSend = {
                user_id: uid,
                template_key: templateKey,
                new_template_name: newLabel
            };
            axiosInstance(token)
                .patch(API_ENDPOINTS.templates, dataToSend)
                .then((response) => {
                    console.log(`Template ${templateKey} renamed to ${newLabel}`);
                    fetchTemplates();
                    message.success('Template renamed');
                })
                .catch((error) => {
                    console.log(error);
                    console.log(error.response.data.message);
                    message.error('Template not renamed');
                });
        }
    };

    const deleteTemplate = () => {
        const templateKey = selectedTemplateKey;
        const label = templates.find((template) => template.key === templateKey)?.label;
        const confirmDelete = window.confirm(`Are you sure you want to delete the template "${label}"?`);

        if (confirmDelete) {
            const dataToSend = {
                user_id: uid,
                template_key: templateKey
            };
            console.log(dataToSend)
            axiosInstance(token)
                .delete(API_ENDPOINTS.templates, { "data": dataToSend })
                .then((response) => {
                    console.log('Template deleted ', response);
                    // Reset selectedTemplateKey to clear the selection
                    setSelectedTemplateKey('');
                    fetchTemplates(); // Fetch updated list of templates
                    message.success('Template deleted');
                })
                .catch((error) => {
                    console.log(error);
                    message.error('Template not deleted');
                });
        }
    };

    const pullTemplate = useCallback(async () => {
        const label = selectedTemplateLabel;
        message.loading('Loading template');
        console.log(`Pulling template (${label})`);

        axiosInstance(token)
            .get(`${API_ENDPOINTS.templatesUpload}?user_id=${uid}&template_key=${selectedTemplateKey}`)
            .then((response) => {
                const result = response.data.data;
                console.log(result);
                setUpdatePromptTemplate(result[0].prompt_template.prompt);
                const inputVariables = result[0].prompt_template.input_variables;
                const inputVariablesString = inputVariables.join(', ');
                setUpdatePromptVariables(inputVariablesString);
                message.success('Template loaded');
            })
            .catch((error) => {
                console.log(error);
                message.error('Template not uploaded - did you select a template?');
            });
    }, [token, uid, selectedTemplateKey, selectedTemplateLabel]);


    const postData = async () => {
        const url = `${API_ENDPOINTS.templatesUpload}?user_id=${uid}&template_key=${selectedTemplateKey}`;

        try {
            let data;

            if (updatePromptVariables.trim() === '') {
                // Set input_variables as an empty JSON object
                data = {
                    interaction: "qasource",
                    input_variables: [],
                    prompt: updatePromptTemplate
                };
            } else {
                data = {
                    interaction: "qasource",
                    input_variables: updatePromptVariables.split(',').map(variable => variable.trim()),
                    prompt: updatePromptTemplate
                };
            }

            const response = await axiosInstance(token).post(url, data);
            console.log(response.request);
            console.log(data);
            console.log(response.data);
            message.success('Template uploaded');
        } catch (error) {
            console.error(error);
            message.error('Template not uploaded');
        }
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    useEffect(() => {
        fetchTemplates();
    }, [fetchTemplates]);

    useEffect(() => {
        if (selectedTemplateKey) {
            pullTemplate();
        } else {
            setUpdatePromptTemplate('');
            setUpdatePromptVariables('');
        }
    }, [selectedTemplateKey, pullTemplate]);

    return (
        <div>
            <Col span={16} height={'100%'} width={'100%'} align={'center'}>
                <Card hoverable
                    actions={[
                        <Button type="primary" onClick={createTemplate}>
                            New
                        </Button>,
                        <Button onClick={renameTemplate}>
                            Rename
                        </Button>,
                        <Popconfirm
                            title="Are you sure you want to delete the template?"
                            okText="Delete"
                            okType="danger"
                            cancelText="Cancel"
                            onConfirm={deleteTemplate}
                        >
                            <Button danger>
                                Delete
                            </Button>
                        </Popconfirm>,
                    ]}
                >
                    <Select
                        showSearch
                        onSearch={onSearch}
                        optionFilterProp="children"
                        id="collection-select"
                        value={selectedTemplateKey}
                        align={'left'}
                        onChange={(value, option) => {
                            setSelectedTemplateKey(value);
                            setSelectedTemplateLabel(option.children);
                        }}
                        style={{ width: '100%' }}
                    >
                        <Option value="">Select Template</Option>
                        {templates.map((template) => (
                            <Option key={template.template_key} value={template.template_key}>
                                {template.template_name}
                            </Option>
                        ))}
                    </Select>
                </Card>
                <br></br>
                <Card
                    className="custom-card"
                    hoverable
                >
                    <Tabs
                        tabBarExtraContent={
                            <>
                                <Space>
                                    <Button size="small" onClick={pullTemplate}>Refresh</Button>
                                    <Popconfirm
                                        title="Are you sure you want to update the template?"
                                        okText="Update"
                                        okType="warning"
                                        cancelText="Cancel"
                                        onConfirm={postData}
                                    >
                                        <Button size="small" type="primary" disabled={!jsonData}>Update</Button>
                                    </Popconfirm>
                                </Space>
                            </>
                        }
                    >
                        <Tabs.TabPane
                            tab={
                                selectedTemplateKey ? (
                                    <Typography.Text>Update prompt details for {selectedTemplateLabel}</Typography.Text>
                                ) : (
                                    <Typography.Text>Please select a template to update prompt</Typography.Text>
                                )
                            }
                            key="1">
                            <Card
                                title="Update User Prompt Variables"
                                className='custom-card'

                                size="small"
                                type="inner"
                                align={'left'}
                            >
                                <Input
                                    placeholder="Variable1, Variable2, Variable3"
                                    value={updatePromptVariables}
                                    onChange={(e) => setUpdatePromptVariables(e.target.value)}
                                />
                            </Card>
                            <br></br>
                            <Card
                                title="Update Prompt Template"
                                className="custom-card"

                                size="small"
                                type="inner"
                            >
                                <TextArea
                                    placeholder="Please paste your prompt here. Remember to include {variables} ...."
                                    rows={4}
                                    value={updatePromptTemplate}
                                    onChange={(e) => setUpdatePromptTemplate(e.target.value)}
                                />
                            </Card>
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </Col>
        </div>);
};


export default PromptTemplate;