
import React, { useEffect } from 'react';
import { Card, Drawer, Select } from 'antd';

import QADrawer from './QADrawer';
import SummaryDrawer from './SummaryDrawer';
import TabularDrawer from './TabularDrawer';
import CommentParserDrawer from './CommentParserDrawer';

const ModelPropertiesDrawer = ({
    visible,
    onClose,
    selectedTab,

    handleModelDetailsSave,
    handleModelDetailsRefresh,
    handleConversationChange,
    handleTemplateChange,
    handleCollectionChange,

    fetchConversationsData,
    fetchConversationHistoryData,

    setSelectedCollectionKey,
    selectedCollectionKey,
    selectedCollection,
    setSelectedCollection,

    selectedTemplateKey,
    setSelectedTemplateKey,
    selectedTemplate,

    selectedConversationKey,
    setSelectedConversationKey,

    updatedTemperature,
    setUpdatedTemperature,
    updatedModel,
    setUpdatedModel,
    updatedK,
    setUpdatedK,

    templates,
    conversations,
    collections,
    conversationHistory,

    promptTemplate,
    inputValues,
    setInputValues,

    ...otherProps
}) => {

    const { Option } = Select;


    useEffect(() => {
        if (visible) {
            console.log('This is the selected tab:', selectedTab);

        }
    }, [visible, selectedTab]);

    const onSearch = (value) => {
        console.log('search:', value);
    };


    const CommonCard = (
        <Card
            className='custom-card'
            title="Select collection"
            hoverable
            size="small"
        >
            <div>
                <h3>
                    <label></label>
                </h3>
                <Select
                    showSearch
                    onSearch={onSearch}
                    optionFilterProp="children"
                    style={{ width: '100%' }}
                    value={selectedCollectionKey}
                    onChange={handleCollectionChange}
                >
                    <Option value=''>Select Collection</Option>
                    {collections.map((collection) => (
                        <Option key={collection.collection_id} value={collection.collection_id}>
                            {collection.collection_name}
                        </Option>
                    ))}
                </Select>
                <br></br>
            </div>
        </Card>

    );

    return (
        <Drawer
            title="Model Properties"
            key="ModelPropertiesDrawer"
            placement="right"
            closable={true}
            onClose={onClose}
            open={visible}
            width={window.innerWidth <= 768 ? '100%' : '50%'}
        >
            {/* Drawer settings that are common across all tabs */}
            {CommonCard}

            {/* Drawer settings for the first tab - Q&A */}
            {(!selectedTab || selectedTab === '1') && (
                <QADrawer
                    conversations={conversations}
                    selectedConversationKey={selectedConversationKey}
                    setSelectedConversationKey={setSelectedConversationKey}
                    handleModelDetailsSave={handleModelDetailsSave}
                    handleModelDetailsRefresh={handleModelDetailsRefresh}
                    handleTemplateChange={handleTemplateChange}
                    handleConversationChange={handleConversationChange}
                    conversationHistory={conversationHistory}
                    fetchConversationHistoryData={fetchConversationHistoryData}
                    fetchConversationsData={fetchConversationsData}
                    updatedTemperature={updatedTemperature}
                    setUpdatedTemperature={setUpdatedTemperature}
                    updatedModel={updatedModel}
                    setUpdatedModel={setUpdatedModel}
                    updatedK={updatedK}
                    setUpdatedK={setUpdatedK}
                    templates={templates}
                    promptTemplate={promptTemplate}
                    inputValues={inputValues}
                    setInputValues={setInputValues}
                    selectedTemplateKey={selectedTemplateKey}
                    setSelectedTemplateKey={setSelectedTemplateKey}
                    selectedTemplate={selectedTemplate}
                ></QADrawer>
            )}

            {/* Drawer settings for the second tab - Summary */}
            {selectedTab === '2' && (
                <SummaryDrawer
                    selectedTemplateKey={selectedTemplateKey}
                    selectedCollectionKey={selectedCollectionKey}
                    selectedCollection={selectedCollection}
                    handleTemplateChange={handleTemplateChange}
                    templates={templates}
                ></SummaryDrawer>
            )}

            {/* {selectedTab === '3' && (
                <TabularDrawer>
                    selectedCollectionKey={selectedCollectionKey}
                    selectedCollection={selectedCollection}
                </TabularDrawer>
            )} */}

            {/* {selectedTab === '4' && (
                <CommentParserDrawer>
                    selectedCollectionKey={selectedCollectionKey}
                    selectedCollection={selectedCollection}
                </CommentParserDrawer>
            )} */}
        </Drawer>
    )
};

export default ModelPropertiesDrawer;