import API_ENDPOINTS from '../apiConfig';

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Card, Col, Row, Tabs, Button, message, notification } from 'antd';
import axiosInstance from '../Components/axiosInstance';
import { UserContext } from '../Components/UserContext';

import styles from '../styles/Interactions.module.css';

import QA from '../Components/Interactions/QA';
// import WebsocketHandler from "../Components/Websocket";
import Summary from '../Components/Interactions/Summary';
import Tabular from '../Components/Interactions/Tabular';
import TabularCSV from '../Components/Interactions/TabularCSV';
import CommentParser from '../Components/Interactions/CommentParser';
import LeadGenerator from '../Components/Interactions/LeadGenerator';
import ModelPropertiesDrawer from '../Components/Interactions/ModelPropertiesDrawer/ModelPropertiesDrawer';
import ChatAgent from '../Components/Interactions/ChatAgent';


const { TabPane } = Tabs;

export default function Interactions({
    collections,
    setCollectionList,
    templates,
    setTemplateList,
    conversations,
    setConversations,
    selectedCollectionKey,
    setSelectedCollectionKey,
    selectedCollection,
    setSelectedCollection,
    selectedTemplateKey,
    setSelectedTemplateKey,
    selectedTemplate,
    setSelectedTemplate,
    selectedConversationKey,
    setSelectedConversationKey,
    selectedConversation,
    setSelectedConversation,
    conversationHistory,
    setConversationHistory,
    resultloading,
    setResultloading,
    qaloading,
    setQaloading,
    question,
    setQuestion,
    results,
    setResults,
    sources,
    setSources,
    QASummary,
    setQASummary,
    updatedTemperature,
    setUpdatedTemperature,
    updatedModel,
    setUpdatedModel,
    updatedK,
    setUpdatedK,
    selectedTab,
    setSelectedTab,
    drawerVisible,
    setDrawerVisible,
    promptTemplate,
    setPromptTemplate,
    inputValues,
    setInputValues,
    // openSocket,
    // setOpenSocket,
    docSummaries, setDocSummaries
}
) {

    const { uid } = useContext(UserContext);
    const { token } = useContext(UserContext);

    const [terminalText, setTerminalText] = useState("");

    const showDrawer = () => {
        setDrawerVisible(true);
    };

    const handleDrawerClose = () => {
        setDrawerVisible(false);
    };

    const handleTabChange = (key) => {
        setSelectedTab(key);
    };

    // COLLECTIONS

    useEffect(() => {

        const fetchCollectionList = async () => {
            try {
                const response = await axiosInstance(token).get(
                    `${API_ENDPOINTS.collections}?user_id=${uid}`
                );
                setCollectionList(response.data.data);
            } catch (error) {
                console.log(error);
                // Display error notification to the user
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch collections. Please try again later.',
                })
            }
        };
        fetchCollectionList();
    }, [selectedTab, token, uid]);


    const handleCollectionChange = useCallback((value) => {
        setSelectedCollectionKey(value);

        const selectedCollectionObj = collections.find(
            (collection) => collection.collection_id === value
        );
        if (selectedCollectionObj) {
            setSelectedCollection(selectedCollectionObj.collection_name);
        }
    }, [collections]);


    // CONVERSATIONS

    const fetchConversationsData = useCallback(async () => {
        try {
            const response = await axiosInstance(token).get(`${API_ENDPOINTS.conversations}`, {
                params: {
                    user_id: uid,
                },
            });
            if (response.data && Array.isArray(response.data.data)) {
                setConversations(response.data.data);
            } else {
                throw new Error('Unexpected data structure');
            }
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Error',
                description: 'Failed to fetch conversations. Please try again later.',
            });
        }
    }, [uid, token]);

    useEffect(() => {
        fetchConversationsData();
    }, [fetchConversationsData]);

    const fetchConversationHistoryData = useCallback(async (convoKey) => {
        try {
            const response = await axiosInstance(token).get(`${API_ENDPOINTS.conversations}/history`, {
                params: {
                    user_id: uid,
                    conversation_key: convoKey,
                },
            });
            if (response.data && Array.isArray(response.data.data)) {
                setConversationHistory(response.data.data);
            } else {
                throw new Error('Unexpected data structure');
            }
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Error',
                description: 'Failed to fetch conversation history. Please try again later.',
            });
        }
    }, [uid, token]);

    useEffect(() => {
        if (selectedConversationKey) { // Ensure we have a conversation key before fetching its history.
            fetchConversationHistoryData(selectedConversationKey);
        }
    }, [fetchConversationHistoryData, selectedConversationKey]);

    const handleConversationChange = (value, option) => {
        setSelectedConversationKey(value);
        const selectedConversationObj = conversations.find((conversation) => conversation.key === value);
        if (selectedConversationObj) {
            setSelectedConversation(selectedConversationObj.label);
            console.log(selectedConversation)
        }
        else {
            setSelectedConversation('Memory bypass - no conversation')
        }
    };

    // TEMPLATES

    const handleTemplateChange = (value, option) => {
        setSelectedTemplateKey(value);

        const selectedTemplateObj = templates.find((template) => template.template_key === value);
        if (selectedTemplateObj) {
            setSelectedTemplate(selectedTemplateObj.template_name);
        }
    };


    useEffect(() => {
        const fetchTemplateList = async () => {
            try {
                const response = await axiosInstance(token).get(`${API_ENDPOINTS.templates}`, {
                    params: {
                        user_id: uid,
                    },
                });
                if (response.data && Array.isArray(response.data.data)) {
                    setTemplateList(response.data.data);
                } else {
                    throw new Error('Unexpected data structure');
                }
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch templates. Please try again later.',
                });
            }
        };
        fetchTemplateList();
    }, [selectedTab, token, uid]);



    // MODEL CHANGE

    const handleModelDetailsRefresh = useCallback(async () => {
        if (selectedTemplateKey) {

            try {
                // Fetch model details
                const response = await axiosInstance(token).get(
                    `${API_ENDPOINTS.templates}/details?user_id=${uid}&template_key=${selectedTemplateKey}`
                );
                if (response.data && response.data.data) {

                    setPromptTemplate(response.data.data.template_details.prompt_template);
                    if (response.data.data.user_input_var) {
                        setInputValues(response.data.data.user_input_var);
                    } else {
                        setInputValues({});
                    }
                    setUpdatedTemperature(response.data.data.temperature);
                    setUpdatedModel(response.data.data.template_model);
                    setUpdatedK(response.data.data.template_k);
                    message.success('Model details refreshed successfully!');
                } else {
                    throw new Error('Unexpected data structure');
                }
            } catch (err) {
                console.error(err);
                message.error('Failed to refresh model details. Please make sure a template is selected first!');
            }
        } else {
            message.warning('Please select a template first before refreshing');
        }
    }, [selectedTemplateKey, token, uid, setPromptTemplate, setInputValues, setUpdatedTemperature, setUpdatedModel, setUpdatedK]);


    useEffect(() => {
        if (selectedTemplateKey) {
            handleModelDetailsRefresh();
        }
    }, [selectedTemplateKey, handleModelDetailsRefresh]);


    const handleModelDetailsSave = async () => {

        try {

            await axiosInstance(token).patch(
                `${API_ENDPOINTS.templates}/details?user_id=${uid}&template_key=${selectedTemplateKey}&new_temperature=${updatedTemperature}&new_model=${updatedModel}&new_k=${updatedK}`,
                { "input_variables": inputValues }
            );

            // Fetch the updated temperature after saving
            const response = await axiosInstance(token).get(
                `${API_ENDPOINTS.templates}/details?user_id=${uid}&template_key=${selectedTemplateKey}`
            );
            setUpdatedTemperature(response.data.data.temperature);
            setUpdatedModel(response.data.data.template_model);
            setUpdatedK(response.data.data.template_k);
            setPromptTemplate(response.data.data.template_details.prompt_template);
            message.success('Model details saved successfully!');
        } catch (err) {
            console.error(err);
            message.error('Failed to save model details. Please make sure a template is selected first!');
        }
    };


    // WebsocketHandler({
    //   openSocket,setOpenSocket,
    //   token: token,
    //   onMessage: (message) => {
    //     console.log("Received message:", message);
    //     switch(message.type) {
    //       case "terminal_log":
    //         setTerminalText(prevText => prevText + "\n" + message.data.text);
    //         break;
    //       case "notification":
    //         switch(message.data.type){
    //           case "error":
    //             notification.error({description:message.data.text});
    //             break;
    //           case "warning":
    //             notification.warning({description:message.data.text});
    //             break;
    //           case "success":
    //             notification.success({description:message.data.text});
    //             break;
    //           default:
    //             break;
    //         }
    //         break;
    //       default:
    //         console.log('unrecognized type of websocket message!');
    //         break;
    //     }
    //   }
    // });

    return (
        <div className={styles.mainContainer}>
            <div>
                <Row gutter={20}>
                    {/*  Section 1 */}
                    <Col span={24}>
                        <Card hoverable>
                            <Tabs
                                size="large"
                                tabPosition="top"
                                defaultActiveKey="1"
                                onChange={handleTabChange}
                                tabBarExtraContent={
                                    <Button type="primary" onClick={showDrawer}>
                                        Open Model Properties
                                    </Button>
                                }
                            >
                                <TabPane tab="Q&A Docs" key="1">
                                    <QA
                                        selectedCollectionKey={selectedCollectionKey}
                                        selectedTemplateKey={selectedTemplateKey}
                                        selectedConversationKey={selectedConversationKey}
                                        selectedTemplate={selectedTemplate}
                                        selectedCollection={selectedCollection}
                                        selectedConversation={selectedConversation}
                                        question={question}
                                        setQuestion={setQuestion}
                                        resultloading={resultloading}
                                        results={results}
                                        sources={sources}
                                        qaloading={qaloading}
                                        QASummary={QASummary}
                                        updatedTemperature={updatedTemperature}
                                        updatedModel={updatedModel}
                                        updatedK={updatedK}
                                        promptTemplate={promptTemplate}
                                        inputValues={inputValues}
                                        terminalText={terminalText}
                                        setTerminalText={setTerminalText}
                                    ></QA>
                                </TabPane>
                                <TabPane tab="Summary" key="2">
                                    <Summary
                                        selectedCollectionKey={selectedCollectionKey}
                                        selectedCollection={selectedCollection}
                                        selectedTemplateKey={selectedTemplateKey}
                                        selectedTemplate={selectedTemplate}
                                        docSummaries={docSummaries} setDocSummaries={setDocSummaries}
                                    ></Summary>
                                </TabPane>
                                <TabPane tab="Tabular CSV" key="3">
                                    <TabularCSV
                                        selectedCollectionKey={selectedCollectionKey}
                                        selectedCollection={selectedCollection}
                                    ></TabularCSV>
                                </TabPane>
                                <TabPane tab="Comment Parser" key="4">
                                    <CommentParser
                                        selectedCollectionKey={selectedCollectionKey}
                                        selectedCollection={selectedCollection}
                                    ></CommentParser>
                                </TabPane>
                                <TabPane tab="Lead Generator" key="5">
                                    <LeadGenerator
                                        selectedCollectionKey={selectedCollectionKey}
                                        selectedCollection={selectedCollection}
                                    >
                                    </LeadGenerator>
                                </TabPane>
                                <TabPane tab="Chat Agent" key="6">
                                    <ChatAgent
                                        selectedCollectionKey={selectedCollectionKey}
                                        selectedCollection={selectedCollection}
                                    >
                                    </ChatAgent>
                                </TabPane>
                            </Tabs>
                        </Card>
                    </Col>

                    {selectedTab && <ModelPropertiesDrawer
                        visible={drawerVisible}
                        onClose={handleDrawerClose}

                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}

                        selectedCollectionKey={selectedCollectionKey}
                        selectedCollection={selectedCollection}
                        selectedTemplateKey={selectedTemplateKey}
                        selectedTemplate={selectedTemplate}
                        selectedConversationKey={selectedConversationKey}
                        selectedConversation={selectedConversation}

                        setSelectedConversationKey={setSelectedConversationKey}

                        collections={collections}
                        templates={templates}
                        conversations={conversations}
                        conversationHistory={conversationHistory}

                        handleConversationChange={handleConversationChange}
                        handleTemplateChange={handleTemplateChange}
                        handleCollectionChange={handleCollectionChange}
                        handleModelDetailsRefresh={handleModelDetailsRefresh}
                        handleModelDetailsSave={handleModelDetailsSave}

                        fetchConversationsData={fetchConversationsData}
                        fetchConversationHistoryData={fetchConversationHistoryData}

                        updatedTemperature={updatedTemperature}
                        setUpdatedTemperature={setUpdatedTemperature}
                        updatedModel={updatedModel}
                        setUpdatedModel={setUpdatedModel}
                        updatedK={updatedK}
                        setUpdatedK={setUpdatedK}

                        promptTemplate={promptTemplate}
                        inputValues={inputValues}
                        setInputValues={setInputValues}

                    />}
                </Row>
            </div>
        </div>
    )
};

// export default Interactions;
