import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col, Spin, Collapse, Card, Table, Space, message, notification, Input, Select, Button, List, Typography } from 'antd';
import { UserContext } from '../UserContext';
import axiosInstance from '../axiosInstance';
import API_ENDPOINTS from '../../apiConfig';
import { WordCloud } from '@antv/g2plot';
import isEqual from 'lodash/isEqual';

function parseWordCloud(commentArrayWithKeywords) {
    // Initialize an empty object to hold keywords and their associated comments
    const wordCloudData = {};

    // Iterate through each item in the comment array
    commentArrayWithKeywords.forEach((item) => {
        // Extract the keyword(s) and the entire comment object
        const keyword = item["Keywords"];
        const comment = item; // now, 'comment' is the entire object

        // Check if the keyword is present
        if (keyword) {
            // If the keyword is an array, iterate through each keyword
            if (Array.isArray(keyword)) {
                keyword.forEach((kw) => {
                    // Initialize an empty array for the keyword if it's not already in the object
                    if (!wordCloudData[kw]) {
                        wordCloudData[kw] = [];
                    }
                    // Append the entire comment object to the keyword's array
                    wordCloudData[kw].push(comment);
                });
            } else {
                // For a single keyword string, do the same process as above
                if (!wordCloudData[keyword]) {
                    wordCloudData[keyword] = [];
                }
                wordCloudData[keyword].push(comment);
            }
        }
    });

    // Format the data into an array of objects, each containing a keyword and its comments
    const formattedData = Object.keys(wordCloudData).map((k) => ({
        keyword: k,
        comments: wordCloudData[k],
    }));

    return formattedData;
}

const CommentParser = ({
    selectedCollectionKey,
    selectedCollection,
    selectedDataFile,
    ...otherProps }) => {

    const { uid, token } = useContext(UserContext)
    const { Option } = Select;
    const { Text } = Typography;
    const { Panel } = Collapse;

    const [resultLoading, setResultLoading] = useState(false)
    const [execReportLoading, setExecReportLoading] = useState(false);
    const [excelLoading, setExcelLoading] = useState(false);
    const [answerLoading, setAnswerLoading] = useState(false);

    const [query, setQuery] = useState('');
    const [answer, setAnswer] = useState('');

    const [fileList, setFileList] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState('');
    const wordCloudRef = useRef(null);
    const wordCloudInstance = useRef(null); // Ref to store the word cloud instance
    const [initialDataSet, setInitialDataSet] = useState([]);
    const [currentDataSet, setCurrentDataSet] = useState([]);
    const [currentWCDataSet, setCurrentWCDataSet] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [selectedFilters, setSelectedFilters] = useState([]); // used to generate the titles for word cloud & Q&A
    const [selectedWord, setSelectedWord] = useState(''); // used to generate the titles for word cloud & Q&A
    const [selectedScenario, setSelectedScenario] = useState('');  //used for dummy file selection (can be removed after demo)


    // IMPORTANT
    useEffect(() => {
        setFileList([]);
        setSelectedFileName('');
        // fetchFilesList(selectedCollectionKey); restore after demo - temporarily commenting this out b/c demo is hardcoded to 2 files
    }, [selectedCollectionKey]);  // fetch files list when selectedCollectionKey changes

    useEffect(() => {
        setSelectedScenario(selectedDataFile);
        setSelectedFileName(getFileNameForScenario(selectedDataFile));
    }, [selectedDataFile]);


    useEffect(() => {
        if (currentWCDataSet.length > 0 && wordCloudRef.current) {
            if (wordCloudInstance.current) {
                wordCloudInstance.current.destroy();
                wordCloudInstance.current = null;
            }
            wordCloudRef.current.innerHTML = '';

            try {
                const wordCloudList = currentWCDataSet.map(keyword => ({
                    name: keyword.keyword, // Ensure these fields match your data structure
                    value: keyword.comments.length,
                }));

                // Create a new word cloud
                const config = {
                    autoFit: true,
                    data: wordCloudList,
                    wordField: 'name',
                    weightField: 'value',
                    colorField: 'name',
                    wordStyle: {
                        fontFamily: 'Verdana',
                        fontSize: [20, 40], // if the font size is too large, word cloud may remove some words
                    },
                    tooltip: {
                        visible: true,
                    },
                    random: () => 0.5,
                };

                wordCloudInstance.current = new WordCloud(wordCloudRef.current, config);
                wordCloudInstance.current.on('element:click', (...args) => {
                    const clickedWord = args[0].data.data.datum.name;
                    setSelectedWord(clickedWord); // Set the selected word to be used in the word cloud title
                    setCurrentDataSet(currentWCDataSet.find((item) => item.keyword === clickedWord).comments);
                });
                wordCloudInstance.current.render();

            } catch (error) {
                console.error('Error rendering word cloud:', error);
            }
        }
    }, [currentWCDataSet]);

    useEffect(() => {
        setCurrentWCDataSet(parseWordCloud(currentDataSet));
    }, [currentDataSet]);

    const getFileNameForScenario = (scenario) => {
        switch (scenario) {
            case 'actuals':
                return 'Example_Comments_Demo_Actuals.xlsx';
            case 'forecast':
                return 'Example_Comments_Demo_Forecast.xlsx';
            case 'budget':
                return 'Example_Comments_Demo_Budget.xlsx';
            default:
                return '';
        }
    };

    const handleFileProcessing = async (event) => {
        setCurrentDataSet([]);
        setCurrentWCDataSet([]);
        if (!selectedFileName) {
            message.warning('Please select a file!');
        }
        else {
            setResultLoading(true); // Set loading state to true
            try {
                // Construct file_key with conditional check for selectedFileName
                let file_key = `${uid}/${selectedCollectionKey}`;
                if (selectedFileName && selectedFileName.trim() !== '') {
                    file_key += `/${selectedFileName}`;
                }
                let url = `${API_ENDPOINTS.interactions}/comment_csv?user_id=${uid}&collection_id=${selectedCollectionKey}&file_key=${file_key}`;
                // Further debugging: Log the constructed URL
                console.log("Constructed URL:", url);
                const response = await axiosInstance(token).post(url);
                setInitialDataSet(response.data.response);
                setCurrentDataSet(response.data.response);
                console.log('Data:', response.data.response);
            } catch (error) {
                console.log(error);

                // Display error notification to the user
                notification.error({
                    message: 'Error',
                    description: 'Failed to send question. Please try again later.',
                });
            } finally {
                setResultLoading(false); // Set loading state to false after the request is completed
            }
        }
    };

    const handleReloadData = () => {
        setFilteredInfo({});
        setCurrentDataSet(initialDataSet);
        setSelectedFilters([]); // Reset the selected f ilters - can be removed after demo
        setSelectedWord(''); // Reset the selected word  - can be removed after demo
    };

    const handleExecReport = async () => {
        try {
            setExecReportLoading(true);
            const url = `${API_ENDPOINTS.interactions}/comment_csv/report`;
            const response = await axiosInstance(token).post(url, currentDataSet, {
                responseType: 'blob', // Important: Set the response type to 'blob'
            });

            // Create a new Blob object with the response data
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

            // Create a link element and set the URL to the blob object
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'executive_report.doc'; // Set the default filename for the download

            // Append the link to the body, click it, and remove it
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error generating executive report:', error);
        } finally {
            setExecReportLoading(false);
        }
    };

    const handleExcelExport = async () => {
        const response = await axiosInstance(token).post(`${API_ENDPOINTS.interactions}/comment_csv/excel`, currentDataSet, {
            responseType: 'blob', // Important: set responseType to 'blob'
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'export.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const filtersChanged = !isEqual(filters, filteredInfo);
        if (filtersChanged) {
            let filteredData = currentDataSet;
            Object.keys(filters).forEach(columnKey => {
                if (filters[columnKey]) {
                    filteredData = filteredData.filter(item =>
                        filters[columnKey].includes(item[columnKey])
                    );
                }
            });
            setCurrentDataSet(filteredData);
        }

        const appliedFilters = Object.keys(filters).filter(key => filters[key] != null).map(key => key);
        setSelectedFilters(appliedFilters); // Set the selected filters to be used in the word cloud title

        setFilteredInfo(filters);
    };

    const sendQuestion = async () => {
        setAnswerLoading(true);
        setAnswer('');
        try {
            const url = `${API_ENDPOINTS.interactions}/comment_csv/chat`;
            const response = await axiosInstance(token).post(url, {
                query: query,
                data: currentDataSet,
            });
            // setQuery('');
            setAnswer(response.data);
        }
        catch (error) {
            console.log(error);
            if (error.response) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to send question. Please try again later.',
                });
            }
        }
        finally {
            setAnswerLoading(false);
        }
    };

    const getUniqueValues = (data, dataIndex) => {
        return Array.from(new Set(data.map(item => item[dataIndex]))).map(value => ({
            text: value,
            value: value,
        }));
    };

    const generateColumns = (dataSource) => {
        if (!dataSource || dataSource.length === 0) {
            return [];
        }

        const firstItemKeys = Object.keys(dataSource[0]);
        return firstItemKeys.map((key) => {
            const column = {
                title: key,
                dataIndex: key,
                key: key,
            };

            if (key === 'Keywords') {
                // Special handling for the 'Keywords' column
                column.title = 'Topics';
                column.render = (keywords) => keywords ? keywords.join(', ') : '';
            } else {
                // Add filters for specific columns
                column.filters = getUniqueValues(dataSource, key);
                column.onFilter = (value, record) => record[key].includes(value);
                column.filteredValue = filteredInfo[key] || null;
            }

            return column;
        });
    };

    const columns = generateColumns(currentDataSet);

    const getTitle = (baseTitle, filters, word) => {
        let title = `${baseTitle} for `;
        if (word) {
            title += `${word} Comments with `;
        } else {
            title += `All Comments with `;
        }

        if (filters.length > 0) {
            title += `${filters.join(', ')} Filter`;
        } else {
            title += `No Filters`;
        }

        return title;
    };

    const wordCloudTitle = getTitle("Word Cloud", selectedFilters, selectedWord);
    const qaTitle = getTitle("Q&A", selectedFilters, selectedWord);

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            {/* dummy file selector - temporary for demo */}
            <Collapse defaultActiveKey={['1']}>
                <Panel header={<Text strong>OneStream Demo Connection</Text>} key="1" extra={[
                    <Button
                        type="primary"
                        onClick={handleFileProcessing}
                        loading={resultLoading}
                        disabled={resultLoading}>
                        {resultLoading ? 'Processing...' : 'Process Scenario'}
                    </Button>
                ]}>
                    <Select
                        showSearch
                        optionFilterProp="children"
                        style={{ width: '100%' }}
                        placeholder="Select a scenario"
                        value={selectedScenario}
                        onChange={(value) => {
                            setSelectedScenario(value);
                            setSelectedFileName(getFileNameForScenario(value));
                        }}
                    >
                        <Option value="actuals">Actuals</Option>
                        <Option value="forecast">Forecast</Option>
                        <Option value="budget">Budget</Option>
                    </Select>
                </Panel>
            </Collapse>

            {/* original file selector - restore after demo */}
            {/* <Collapse>
        <Panel header={<Text strong>File sources</Text>} key="1" extra={[
          <Button
            type="primary"
            onClick={handleFileProcessing}
            loading={resultLoading}
            disabled={resultLoading}>
            {resultLoading ? 'Processing...' : 'Process File'}
          </Button>
        ]}>
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            placeholder="Select a file"
            value={selectedFileName}
            onChange={(value) => setSelectedFileName(value)}
          >
            {fileList.map((filename, index) => (
              <Option key={index} value={filename}>{filename}</Option>
            ))}
          </Select>
        </Panel>
      </Collapse> */}
            {resultLoading && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', marginTop: '20px' }}>
                    <Spin spinning={resultLoading} ></Spin>
                </div>
            )}
            {currentWCDataSet.length > 0 && (
                <Card
                    title={wordCloudTitle}
                    style={{ width: '100%' }}
                    extra={
                        <>
                            <Button type="default" onClick={handleReloadData}>
                                Reload Data
                            </Button>
                            <Button type="default" onClick={handleExcelExport} style={{ marginLeft: '10px' }} loading={excelLoading}>
                                Export to Excel
                            </Button>
                            <Button type="primary" onClick={handleExecReport} style={{ marginLeft: '10px' }} loading={execReportLoading}>
                                Executive Report
                            </Button>
                        </>
                    }>
                    <div ref={wordCloudRef} ></div>
                </Card>
            )}
            {currentDataSet.length > 0 && (
                <Collapse>
                    <Card style={{
                        width: '100%', // Set the desired width
                        maxHeight: '600px', // Set the desired height
                        overflow: 'auto'
                    }}>
                        <Table
                            columns={columns}
                            dataSource={currentDataSet}
                            pagination={{ position: ['topRight'], pageSize: 5 }}
                            onChange={handleTableChange}
                            filteredInfo={filteredInfo}
                        />
                    </Card>
                </Collapse>
            )}
            {currentDataSet.length > 0 && (
                <Collapse>
                    <Panel header={<Text strong>{qaTitle}</Text>} key="2">
                        <div><form
                            onSubmit={(e) => {
                                e.preventDefault();
                                sendQuestion();
                            }}
                        >
                            <Input
                                placeholder="Enter your question here and press Enter to send..."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                onPressEnter={() => sendQuestion()}
                                disabled={answerLoading}
                            />
                            {answerLoading &&
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', marginTop: '20px' }}>
                                    <Spin spinning={answerLoading} ></Spin>
                                </div>}
                        </form>
                            <Typography.Paragraph style={{ marginTop: '10px' }}>
                                <Text strong>Answer:</Text>
                                <br />
                                <Input.TextArea
                                    value={answer}
                                    readOnly
                                // autoSize={{ minRows: 3, maxRows: 6 }}
                                />
                            </Typography.Paragraph>
                        </div>
                    </Panel>
                </Collapse>
            )}
        </Space>
    )
}

export default CommentParser;
