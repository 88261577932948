import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Popconfirm } from 'antd';
import { useEffect } from 'react';
const { TextArea } = Input;

export default function DynamicQuestionsForm({
  autoComplete,
  style,
  formName,
  handleQuestionInputChange,
  questionInput,
  updateQuestions,
  setUpdatePromptVariables,
  setUpdatePromptTemplate,
  isTemplate,
  patchTemplate,
  fetchTemplate }) {

  const onFinish = (values) => {
    console.log('Received values of form:', values);
    if (!!updateQuestions) {

      updateQuestions(values.questions);

    }
    else if (isTemplate) {
      setUpdatePromptTemplate(JSON.stringify(values.questions));
      patchTemplate(JSON.stringify(values.questions));
      // fetchTemplate()
    }
  };

  const [questionsForm] = Form.useForm();
  
  useEffect(() => {
    if (JSON.stringify({ questions: questionInput }) !== JSON.stringify(questionsForm.getFieldsValue())) {
      questionsForm.setFieldsValue({ questions: questionInput });
    }
  }, [questionInput])

  return <Form
    form={questionsForm}
    name="questionsForm"
    initialValues={{ questions: questionInput }}
    onFinish={onFinish}
    style={!!style ? style : { width: '100%' }}
    autoComplete={!!autoComplete ? autoComplete : "off"}
  >
    <Form.List
      initialValues={
        {
          questions: questionInput
        }}
      name={"questions"}>
      {(fields, { add, remove, move }) => {
        return (<>
          {fields.map(({ key, name, ...restField }) => {
            return <Space key={key} style={{ display: 'flex', columnGap: 8, width: '100%' }} align="baseline">
              <div style={{ width: '100%', marginRight: 'auto', display: 'flex', gap: 8 }}>
                <div
                  style={{ width: '100%' }}
                >
                  <Form.Item
                    {...restField}
                    style={{ width: '100%' }}
                    name={[name, 'header']}
                    rules={[{ required: true, message: 'Missing Header' }]}
                  >
                    <Input
                      type='text'
                      placeholder="Header" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    style={{ width: '100%' }}
                    name={[name, 'question_detail']}
                    rules={[{ required: true, message: 'Missing Question' }]}
                  >
                    <TextArea rows={4}
                      onChange={handleQuestionInputChange}
                      placeholder="Question" />
                  </Form.Item>
                </div>

                <MinusCircleOutlined
                  onClick={() => remove(name)} />
              </div>
            </Space>
          })}
          <Form.Item>
            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
              Add field
            </Button>
          </Form.Item>
        </>)
      }}
    </Form.List>
    <Form.Item>
      <Popconfirm
        title="Are you sure you want to update the template?"
        okText="Update"
        okType="warning"
        cancelText="Cancel"
        onConfirm={() => questionsForm.submit()}
      >
        <Button type="primary">
          Submit
        </Button>
      </Popconfirm>
    </Form.Item>
  </Form>
};