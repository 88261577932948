import API_ENDPOINTS from '../../apiConfig';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Button, message, Select, Card, Typography, Input, Tabs, Row, Col, Popconfirm, Space, Spin } from 'antd';
import axiosInstance from '../axiosInstance';
import { UserContext } from '../UserContext';
import DynamicQuestionsForm from '../DynamicQuestionsForm'

import styles from '../../styles/Collections.module.css';
import 'antd/dist/reset.css';

const { Option } = Select;
const { TextArea } = Input;


const ReportTemplate = ({
    templates,
    setTemplates,
    selectedTemplateKey,
    setSelectedTemplateKey
}) => {
    const { uid, token } = useContext(UserContext);

    const [selectedTemplateName, setSelectedTemplateName] = useState('');
    const [updatePromptVariables, setUpdatePromptVariables] = useState([]);
    const [updatePromptTemplate, setUpdatePromptTemplate] = useState([]);
    const [updateTemplateQuestions, setUpdateTemplateQuestions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const createTemplate = () => {
        const template_name = prompt('Enter a name for the new template:');
        if (template_name) {
            axiosInstance(token)
                .post(`${API_ENDPOINTS.templates}/report?template_name=${template_name}&user_id=${uid}`)
                .then((response) => {
                    fetchTemplates();
                    setSelectedTemplateKey(response.data.data);
                    message.success('Template created');
                })
                .catch((error) => {
                    console.log(error);
                    message.error('Template not created');
                });
        }
    }

    const deleteTemplate = () => {
        //const name = templates.find((template) => template.key === selectedTemplateKey)?.name;
        axiosInstance(token)
            .delete(`${API_ENDPOINTS.templates}/report?template_id=${selectedTemplateKey}&user_id=${uid}`)
            .then((response) => {
                // Reset selectedTemplateKey to clear the selection
                setSelectedTemplateKey('');
                fetchTemplates(); // Fetch updated list of templates
                message.success('Template deleted');
            })
            .catch((error) => {
                console.log(error);
                message.error('Template not deleted');
            });
    };

    //retrives template details and loads into page
    const renameTemplate = async () => {
        const template_name = prompt('Enter a new name for the template:');
        if (template_name) {
            const url = `${API_ENDPOINTS.templates}/report/rename?template_name=${template_name}&template_id=${selectedTemplateKey}&user_id=${uid}`;
            try {
                const response = await axiosInstance(token).patch(url);
                message.success('Template renamed');
                fetchTemplates();
            } catch (error) {
                console.error(error);
                message.error('Template not renamed');
            }
        }
    }
    const fetchTemplate = useCallback(async () => {
        setIsLoading(true)
        setUpdatePromptVariables([]);
        setUpdatePromptTemplate([]);
        setUpdateTemplateQuestions([])
        if (selectedTemplateKey) {
            message.loading('Loading template');

            axiosInstance(token)
                .get(`${API_ENDPOINTS.templates}/report?user_id=${uid}&template_id=${selectedTemplateKey}`)
                .then((response) => {
                    const result = response.data.data;
                    setSelectedTemplateName(result.template_name)
                    setUpdatePromptTemplate(result?.prompt?.length > 0 ? JSON.parse(result.prompt) : {});
                    const inputVariables = (Object.keys(result.input_variables).length === 0) ? '' : result.input_variables.join(', ');
                    setUpdatePromptVariables(inputVariables);
                    message.success('Template loaded');
                })
                .catch((error) => {
                    console.log(error);
                    message.error('Template not uploaded - did you select a template?');
                });
        }
        setIsLoading(false)
    }, [token, uid, selectedTemplateKey, selectedTemplateName]);

    //retrieves list of templates from postgres
    const fetchTemplates = useCallback(() => {
        axiosInstance(token)
            .get(`${API_ENDPOINTS.templates}/report?user_id=${uid}`)
            .then((response) => {
                setTemplates(response.data.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [token, uid]);

    const patchTemplate = async (prompTemplate) => {
        const url = `${API_ENDPOINTS.templates}/report/upload?template_id=${selectedTemplateKey}&user_id=${uid}`;
        try {
            const data = {
                input_variables: [''],
                prompt: !!prompTemplate ? prompTemplate : updatePromptTemplate
            };

            const response = await axiosInstance(token).patch(url, data);
            message.success('Template uploaded');
            fetchTemplate();
        } catch (error) {
            console.error(error);
            message.error('Template not uploaded');
        }
    }
    useEffect(() => {
        fetchTemplate();
    }, [selectedTemplateKey])

    return (
        <div>
            <Col span={16} height={'100%'} width={'100%'} align={'center'}>
                <Card hoverable
                    actions={[
                        <Button type="primary" onClick={createTemplate}>
                            New
                        </Button>,
                        <Button onClick={renameTemplate}>
                            Rename
                        </Button>,
                        <Button onClick={fetchTemplate}>Refresh</Button>,
                        <Popconfirm
                            title="Are you sure you want to delete the template?"
                            okText="Delete"
                            okType="danger"
                            cancelText="Cancel"
                            onConfirm={deleteTemplate}
                        >
                            <Button danger>
                                Delete
                            </Button>
                        </Popconfirm>,
                    ]}
                >
                    <Select
                        showSearch
                        optionFilterProp="children"
                        id="collection-select"
                        value={selectedTemplateKey}
                        align={'left'}
                        onChange={(value, option) => {
                            setSelectedTemplateKey(value);
                            setSelectedTemplateName(option.children);
                        }}
                        style={{ width: '100%' }}
                    >
                        <Option value="">Select Template</Option>

                        {templates.map((template) => (
                            <Option key={template.template_id} value={template.template_id}>
                                {template.template_name}
                            </Option>
                        ))}
                    </Select>
                </Card>
                <br></br>
                <Card
                    className="custom-card"
                    hoverable
                >
                    <Tabs
                    >
                        <Tabs.TabPane
                            tab={
                                selectedTemplateKey ? (
                                    <Typography.Text>Update prompt details for {selectedTemplateName}</Typography.Text>
                                ) : (
                                    <Typography.Text>Please select a template to update prompt</Typography.Text>
                                )
                            }
                            key="1">
                            {isLoading ? (
                                <Spin size="large" />
                            ) :
                                <DynamicQuestionsForm
                                    questionInput={updatePromptTemplate}
                                    setUpdatePromptVariables={setUpdatePromptVariables}
                                    setUpdatePromptTemplate={setUpdatePromptTemplate}
                                    patchTemplate={patchTemplate}
                                    fetchTemplate={fetchTemplate}
                                    isTemplate={true}
                                ></DynamicQuestionsForm>}
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            </Col>
        </div>);
};


export default ReportTemplate;