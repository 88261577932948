import API_ENDPOINTS from '../apiConfig';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Card, Steps, Row, Col } from 'antd';
import { UserContext } from '../Components/UserContext';
import axiosInstance from '../Components/axiosInstance'; // Import the axiosInstance function

import styles from '../styles/Collections.module.css';
import 'antd/dist/reset.css';

import ReportSetup from '../Components/Reports/ReportSetup';
import Preview from '../Components/Reports/Preview';
import Review from '../Components/Reports/Review';

const { Step } = Steps;

const Reports = ({
    docSummaries, setDocSummaries
}) => {
    const { uid, token,
        currentStep, setCurrentStep,
        reportsCollectionsList, setReportsCollectionList,
        reports, setReportList,
        reportLoading, setReportLoading,
        selectedReportsTemplateKey, setSelectedReportsTemplateKey,
        reportTemplates, setReportTemplates,
        selectedReport, setSelectedReport,
        selectedReportKey, setSelectedReportKey,
        selectedReportsCollectionKey, setSelectedReportsCollectionKey,
        selectedCollection, setSelectedCollection,
    } = useContext(UserContext);
    
    const [questions, setQuestions] = useState([]);
    const [defaultOrderQuestions, setDefaultOrderQuestions] = useState([]);

    const fetchCollectionsList = async () => {
        try {
            const response = await axiosInstance(token).get(`${API_ENDPOINTS.collections}?user_id=${uid}`);
            let collections = response.data.data;

            // Fetch files for each collection and add to the collection object
            // REFACTOR: This should be done in the back-end with 1 axios call.
            const collectionFilesPromises = collections.map(async (collection) => {
                const filesResponse = await axiosInstance(token).get(`${API_ENDPOINTS.collectionsUpload}?user_id=${uid}&collection_id=${collection.collection_id}`);
                collection.files = filesResponse.data.data;
                return collection;
            });

            collections = await Promise.all(collectionFilesPromises);

            setReportsCollectionList(collections);
        } catch (error) {
            console.log('Error fetching collections:', error);
        }
    };


    useEffect(() => {
        fetchCollectionsList();
    }, [token, uid, setReportsCollectionList]);

    const fetchTemplates = async () => {
        try {
            const response = await axiosInstance(token).get(`${API_ENDPOINTS.templates}/report?user_id=${uid}`);
            const data = response.data;
            data.data = data.data.map(data => {
                data.input_variables = Object.values(data.input_variables)
                return data;
            })
            setReportTemplates(data.data);
            setSelectedReportsTemplateKey(data.templates?.length > 0 ? data.templates[0].template_id : '');
        } catch (error) {
            console.log('Error fetching templates:', error);
        }
    };

    useEffect(() => {
        fetchTemplates();
        fetchReports();
    }, [token, uid, setReportTemplates]);


    const fetchReports = useCallback(async () => {
        setReportLoading(true);
        try {
            const response = await axiosInstance(token).get(`${API_ENDPOINTS.reports}?user_id=${uid}`);
            const data = response.data.data;
            if (!!data) {
                setReportList(data);
            }
            if (currentStep === 0 && selectedReportKey?.length === 0) {
                //do these state changes need to occur? The effect hook messes up whenever a report is loaded into preview. 
                //setSelectedReport(data[0].label);
                //setSelectedReportKey(data[0].key);
            }
        } catch (error) {
            console.log('Error fetching reports:', error);
        }
        setReportLoading(false);
    }, [token, uid, selectedReport, selectedReportKey]);



    const handleCollectionChange = useCallback((value, option) => {
        const selectedCollectionObj = reportsCollectionsList.find((collection) => collection.collection_id === value);
        if (selectedCollectionObj) {
            setSelectedCollection(selectedCollectionObj.label);
            setSelectedReportsCollectionKey(selectedCollectionObj.key);
            console.log('This is selected collection->', selectedCollection, selectedReportsCollectionKey);
        }
    }, [reportsCollectionsList, selectedCollection, selectedReportsCollectionKey]);


    const handleReportNameChange = (value) => {
        const selectedReport = reports.find(report => report.key === value);
        if (selectedReport) {
            setSelectedReportKey(selectedReport.key);
            setSelectedReport(selectedReport.label);
            setSelectedReportsCollectionKey(selectedReport.nskey);
            console.log("this is the obj from the report object", selectedReport)
            const selectedCollection = reportsCollectionsList.find(collection => collection.collection_id === selectedReport.nskey);
            if (selectedCollection) {
                setSelectedCollection(selectedCollection.label);
                setSelectedReportsCollectionKey(selectedCollection.key);
            }
        }
    };

    useEffect(() => {
        const selectedCollection = reportsCollectionsList.find(collection => collection.collection_id === selectedReportsCollectionKey);
        if (selectedCollection) {
            setSelectedCollection(selectedCollection.label);
        }
    }, [selectedReportsCollectionKey, reportsCollectionsList]);

    const handleStepChange = (step) => {
        setCurrentStep(step);
    };

    return (
        <div className={styles.mainContainer}>
            <div>
                <Row gutter={20}>
                    <Col span={24}>
                        <div>
                            <Card>
                                <Steps current={currentStep} onChange={handleStepChange}>
                                    <Step title="Report Setup" status={currentStep === 0 ? 'process' : 'finish'} />
                                    <Step title="Preview Responses" status={currentStep === 1 ? 'process' : currentStep > 1 ? 'finish' : 'wait'} />
                                    <Step title="Review Filing" status={currentStep === 2 ? 'process' : currentStep > 2 ? 'finish' : 'wait'} />
                                </Steps>
                            </Card>
                            <br></br>
                            <Card>
                                {currentStep === 0 && (
                                    <div>
                                        <ReportSetup
                                            setCurrentStep={setCurrentStep}
                                            selectedCollection={selectedCollection}
                                            selectedReportKey={selectedReportKey}
                                            setSelectedReportKey={setSelectedReportKey}
                                            selectedReport={selectedReport}
                                            setSelectedReport={setSelectedReport}
                                            collections={reportsCollectionsList}
                                            reports={reports}
                                            fetchReports={fetchReports}
                                            setReportList={setReportList}
                                            reportLoading={reportLoading}
                                            questions={questions}
                                            setQuestions={setQuestions}
                                            templates={reportTemplates}
                                            setTemplates={setReportTemplates}
                                            selectedTemplateKey={selectedReportsTemplateKey}
                                            setSelectedTemplateKey={setSelectedReportsTemplateKey}
                                        />
                                    </div>
                                )}
                                {currentStep === 1 && (
                                    <div>
                                        <Preview
                                            setCurrentStep={setCurrentStep}
                                            selectedCollectionKey={selectedReportsCollectionKey}
                                            setSelectedCollectionKey={setSelectedReportsCollectionKey}
                                            selectedReportKey={selectedReportKey}
                                            setSelectedReportKey={setSelectedReportKey}
                                            selectedReport={selectedReport}
                                            setSelectedReport={setSelectedReport}
                                            selectedTemplateKey={selectedReportsTemplateKey}
                                            setSelectedTemplateKey={setSelectedReportsTemplateKey}
                                            questions={questions}
                                            setQuestions={setQuestions}
                                            setDefaultOrderQuestions={setDefaultOrderQuestions}
                                            // openSocket={openSocket}
                                            // setOpenSocket={setOpenSocket}
                                            docSummaries={docSummaries} setDocSummaries={setDocSummaries}
                                        />
                                    </div>
                                )}
                                {currentStep === 2 && (
                                    <div>
                                        <br />
                                        <Review
                                            reports={reports}
                                            setCurrentStep={setCurrentStep}
                                            selectedCollection={selectedCollection}
                                            selectedCollectionKey={selectedReportsCollectionKey}
                                            setSelectedCollectionKey={setSelectedReportsCollectionKey}
                                            selectedReportKey={selectedReportKey}
                                            setSelectedReportKey={setSelectedReportKey}
                                            selectedReport={selectedReport}
                                            setSelectedReport={setSelectedReport}
                                            questions={questions}
                                            setQuestions={setQuestions}
                                            defaultOrderQuestions={defaultOrderQuestions}
                                            setDefaultOrderQuestions={setDefaultOrderQuestions}
                                        />
                                    </div>
                                )}
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Reports;
