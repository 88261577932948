import API_ENDPOINTS from '../../../apiConfig';
import React, { useContext, useState, useEffect } from 'react';
import { Collapse, Card, Select, notification } from 'antd';
import { UserContext } from '../../UserContext';
import axiosInstance from '../../axiosInstance';

const { Panel } = Collapse;
const { Option } = Select;

const CommentParserDrawer = ({
  selectedCollectionKey,
  selectedCollection,
  promptTemplate,
  inputValues,
  setInputValues,
  ...otherProps
}) => {
  const { uid, token } = useContext(UserContext);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    fetchFilesList(selectedCollectionKey);
  }, [selectedCollectionKey]);  // fetch files list when selectedCollectionKey changes

  const fetchFilesList = (key) => {
    axiosInstance(token)
        .get(`${API_ENDPOINTS.collectionsUpload}?user_id=${uid}&collection_id=${"Comment_example_6UVN"}`)
      .then((response) => {
        const fileNames = response.data.data.map(file => file.file_name);
        setUploadedFiles(fileNames);
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          notification.error({
            message: 'Error',
            description: 'Failed to pull list of collection. Please try again later.',
          });
        }
      });
  };

  return (
    <div>
      <br />
      <Card
        hoverable
        size="small"
        align="left"
        className="custom-card"
        title="Select file"
      >
        <div>
          <Select
            showSearch
            optionFilterProp="children"
            style={{ width: '100%' }}
            placeholder="Select a file"
          >
            {uploadedFiles.map((filename, index) => (
              <Option key={index} value={filename}>{filename}</Option>
            ))}
          </Select>
        </div>
      </Card>
      <br />
    </div>
  );
};

export default CommentParserDrawer;
