import React, { useState, useEffect, useContext, useRef } from 'react';
import { Avatar, Row, Col, Spin, Card, notification, Button, Modal, Tooltip } from 'antd';
import { UserContext } from '../UserContext';
import axiosInstance from '../axiosInstance';
import API_ENDPOINTS from '../../apiConfig';
import TextArea from 'antd/es/input/TextArea';
import { v4 as uuidv4 } from 'uuid';  

// Components to be loaded as modal
import CommentParser from './CommentParser';
import Reports from '../../Pages/Reports';



const DemoAgent = () => {
    const initialMessage = "Hello, how can I help you?";
    const { uid, token, setCurrentStep, setSelectedReportKey, setSelectedReport} = useContext(UserContext);

   


    const [resultLoading, setResultLoading] = useState(false);
    const [conversation, setConversation] = useState([
        {
            type: "ai",
            elements: [
                { text: initialMessage },
            ]
        }
    ]);
    const messagesEndRef = useRef(null);
    const [question, setQuestion] = useState('');
    const [selectedDataFile, setSelectedDataFile] = useState(null); // Ensure it only accepts specific strings
    const [threadId, setThreadId] = useState(uuidv4());  // Initial thread_id

    // Single state for modal visibility and modal content type
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContentType, setModalContentType] = useState(''); // e.g., 'comment_parser' or 'report_module'

    const sendQuestion = async (query) => {
        if (!query.trim()) return;

        const parserMapping = {
            'start_comment_parser_actuals': 'actuals',
            'start_comment_parser_forecast': 'forecast',
            'start_comment_parser_budget': 'budget',
        };

        if (parserMapping[query]) {
            setSelectedDataFile(parserMapping[query]);
            setModalContentType('comment_parser'); // Set the modal type to comment parser
            setIsModalOpen(true); // Open the modal
            return;
        }

        if (query === 'start_report_module') {
            setModalContentType('report_module'); // Set the modal type to report module
            setCurrentStep(1);
            setSelectedReportKey("Market Analysis LB Report_TIGT");
            setSelectedReport("Market Analysis LB Report");
            setIsModalOpen(true); // Open the modal
            return;
        }

        setResultLoading(true);
        setConversation(prevConversation => [...prevConversation, { type: 'human', elements: [{ text: query }] }]);

        try {
            const url = `${API_ENDPOINTS.demo}/demo_agent?user_id=${uid}&thread_id=${threadId}`;
            const response = await axiosInstance(token).post(url, { query });

            setConversation(prev => [...prev, { type: 'ai', elements: response.data.response.elements }]);

        } catch (error) {
            console.error(error);
            notification.error({
                message: 'Error',
                description: 'Failed to send question. Please try again later.',
            });
        } finally {
            setResultLoading(false);
            setQuestion('');  // Clear the input box after sending the message
        }
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const handleOptionClick = (value) => {
        // Check if the value is a URL (starts with "http")
        if (typeof value === 'string' && value.startsWith('http')) {
            window.open(value, '_blank'); // Open the URL in a new tab
        } else {
            sendQuestion(value); // Send the question if it's not a URL
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [conversation]);

    const clearChat = () => {
        setConversation([{ type: "ai", elements: [{ text: initialMessage }] }]);
        setThreadId(uuidv4());  // Generate a new UUID when clearing chat
    };

    const handleCancel = () => {
        setIsModalOpen(false);  // Close the modal
        setModalContentType('');  // Reset the modal content type
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', height: '70vh', width: '100%' }}>
            <div style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                flexGrow: 1,
            }}>
                {conversation.map((entry, index) => (
                    <div key={index} style={{
                        display: 'flex', width: '100%', justifyContent: 'flex-start', marginBottom: '10px'
                    }}>
                        <Card
                            size="small"
                            style={{
                                whiteSpace: 'pre-wrap',
                                border: entry.type === 'human' ? '2px solid #1890ff' : undefined // Add blue border if type is human
                            }}
                        >
                            {entry.elements.map((element, elIndex) => (
                                element.text ? (
                                    <p key={elIndex} style={{ margin: '0' }}>{element.text}</p>
                                ) : (
                                    <Tooltip key={elIndex} title={element.value}>
                                        <Button
                                            onClick={() => handleOptionClick(element.value)}
                                            style={{ display: 'block', margin: '5px 0' }}
                                        >
                                            {element.label}
                                        </Button>
                                    </Tooltip>
                                )
                            ))}
                        </Card>
                    </div>
                ))}

                <div ref={messagesEndRef} />
            </div>
            <div style={{ padding: '10px 20px', backgroundColor: '#fff', width: '100%' }}>
                <Row justify="center" style={{ width: '100%' }}>
                    <Col span={24} style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                        <TextArea
                            rows={2}
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            onPressEnter={(e) => {
                                e.preventDefault();  // Prevent the default form submission behavior
                                sendQuestion(question);
                            }}
                            placeholder="Type your message here..."
                            disabled={resultLoading}
                            style={{
                                width: '100%', // Matches the width of the message box above
                                margin: '0 auto', // Centers the TextArea horizontally
                                minHeight: '200px', // Ensures the TextArea is always visible
                            }}

                        />
                        {resultLoading && (
                            <Spin style={{
                                position: 'absolute',
                                zIndex: 1,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                background: 'rgba(255, 255, 255, 0.8)' // Semi-transparent background
                            }} />
                        )}
                    </Col>
                </Row>
                <Row justify="center" style={{ width: '100%', marginTop: '10px' }}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button danger onClick={clearChat} style={{ marginRight: '8px' }} disabled={resultLoading}>
                            New Thread
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => sendQuestion(question)}
                            loading={resultLoading}
                            disabled={resultLoading || !question.trim()}>
                            Send
                        </Button>
                    </Col>
                </Row>

                {/* Single Modal */}
                <Modal
                    title={modalContentType === 'comment_parser' ? "Comment Parser" : "Report Module"}
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                    width="90%"
                >
                    {/* Conditional rendering of modal content based on the modalContentType */}
                    {modalContentType === 'comment_parser' && (
                        <CommentParser
                            selectedCollectionKey="ed69934d-3f58-4b95-ac3d-245a207d813c"
                            selectedCollection="Comments Dataset"
                            selectedDataFile={selectedDataFile}
                        />
                    )}
                    {modalContentType === 'report_module' && (
                        <Reports 
                            


                        />
                    )}
                </Modal>
            </div>
        </div>
    );
}

export default DemoAgent;