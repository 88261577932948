import API_ENDPOINTS from '../../apiConfig';
import React, { useContext, useState, useEffect } from 'react';
import { Button, Card, Col, Modal, Row, Space, Table, notification } from 'antd';
import axiosInstance from '../axiosInstance';
import { UserContext } from '../UserContext';
import { ReactSortable } from "react-sortablejs";
import styles from '../../styles/Review.module.css';

import 'antd/dist/reset.css';

const { confirm } = Modal;

const Review = ({
    setCurrentStep,
    selectedCollectionKey,
    setSelectedCollectionKey,
    selectedCollection,
    selectedReportKey,
    setSelectedReportKey,
    selectedReport,
    setSelectedReport,
    reports,
    questions,
    setQuestions,
    defaultOrderQuestions,
    setDefaultOrderQuestions,
    ...otherProps
}) => {


    const { uid } = useContext(UserContext);
    const { token } = useContext(UserContext);
    const [questionOrderModalVisible, setQuestionOrderModalVisible] = useState(false);
    const [save, setSave] = useState(true)
    const [savedQuestions, setSavedQuestions] = useState([]);
    const [isNoResponse, setIsNoResponse] = useState(false)
    const fetchQuestions = async () => {
        try {
            const url = `${API_ENDPOINTS.reports}/questions?user_id=${uid}&report_id=${selectedReportKey}`;
            const response = await axiosInstance(token).get(url);
            const data = response.data;
            const sortedData = data.data.questions.map((question, index) => ({
                ...question,
                order: question.order || index + 1,
                question: question.question_detail || '',
                header: question.header || '',
            }))
            setQuestions(sortedData);
        } catch (error) {
            console.log('Error fetching questions:', error);
            return [];
        }
    };
    useEffect(() => {
        fetchQuestions();
    }, [uid]);


    const showQuestionOrderModal = () => {
        setQuestionOrderModalVisible(true);
    };

    const handleQuestionOrderModalCancel = () => {
        setQuestionOrderModalVisible(false);
    };


    const handleQuestionOrderModalOk = () => {
        setQuestionOrderModalVisible(false);
        setSavedQuestions(questions);
    };


    const handleExportConfirm = (documentType) => {
        if (documentType === 'Word') {
            confirm({
                title: 'Export to Word',
                content: 'Are you sure you want to export to Word?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: exportToWord,
            });
        }
        else {
            confirm({
                title: 'Export to Excel',
                content: 'Are you sure you want to export to Excel?',
                okText: 'Yes',
                cancelText: 'No',
                onOk: exportToExcel,
            });
        }

    };
    const exportToExcel = async () => {
        try {
            const payload = { reportId: selectedReportKey, docType: 'Excel' }
            const response = await axiosInstance(token).post(
                `${API_ENDPOINTS.reports}/final?user_id=${uid}&report_id=${selectedReportKey}`,
                payload
            );
            if (response?.data === null) {
                notification.error({ message: !!response?.message ? response?.message : 'Export to Word failed' });
                return;
            }
            const fileUrl = response.data.data; // assuming the URL to the file is returned directly
            if (fileUrl === 'undefined') {
                notification.error({ message: !!response?.data.message ? response?.data.message : 'Export to Word failed' });
                return;
            }
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'report.xls');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            notification.success({ message: 'Export to Excel successful' });
        } catch (error) {
            notification.error({ message: 'Export to Excel failed' });
        }
    };

    const exportToWord = async () => {
        try {
            const payload = { reportId: selectedReportKey, docType: 'Word' }
            const response = await axiosInstance(token).post(
                `${API_ENDPOINTS.reports}/final?user_id=${uid}&report_id=${selectedReportKey}`,
                payload
            );
            const fileUrl = response.data.data; // assuming the URL to the file is returned directly
            if (fileUrl === 'undefined') {
                notification.error({ message: !!response?.data.message ? response?.data.message : 'Export to Word failed' });
                return;
            }
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', 'report.docx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            notification.success({ message: 'Export to Word successful' });
        } catch (error) {
            console.log('Error exporting to Word:', error);
            notification.error({ message: 'Export to Word failed' });
        }
    };


    const columns = [
        {
            title: 'Header',
            dataIndex: 'header',
            key: 'header',
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
        },
    ];

    const saveQuestionOrderToTable = async () => {
        setSave(false)
        const data = questions.map((question, index) => {
            question.order = index + 1;
            return question;
        })
        try {
            const payload = { questions: data };
            const url = `${API_ENDPOINTS.reports}/questions?user_id=${uid}&report_id=${selectedReportKey}`
            await axiosInstance(token)
                .post(url, payload);
            notification.success({ message: 'Question order saved successfully' });
        } catch (error) {
            notification.error({ message: 'Error', description: 'Failed to save question order' });
            console.log('Error updating questions:', error);
            return [];
        }
        setSave(true)
    };

    const tableData = [
        {
            key: '1',
            title: `${selectedReport} - Word Report`,
            type: 'Word'
        },
        {
            key: '2',
            title: `${selectedReport} - Excel Visuals`,
            type: 'Excel',
            // downloadLink: 'https://canurta-s3-docs.s3.amazonaws.com/canurta-gpt-docs/reports/bec2e2bf70d0d2ca/ReportTest1_R2RJ/reportCharts.xlsx'
        },
    ];

    const tableColumns = [
        {
            title: 'Document',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Download',
            key: 'downloadLink',
            render: (text, record) => {
                if (record.type?.length > 0) {
                    return <button onClick={() => handleExportConfirm(record.type)} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>Link</button>;
                }
            },
        },
    ];



    return (
        <div>
            <Card
                title={
                    selectedReport
                        ? `Review Filing for ${selectedReport}`
                        : 'Please select a report on Step 1!'
                }
                hoverable
                className="custom-card"
                extra={[
                    <Space size="small" direction="horizontal" key="extra-space">
                        <Button size="middle"
                            // disabled={questions.length === 0}
                            onClick={showQuestionOrderModal}>
                            Reorder
                        </Button>
                    </Space>,
                ]}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Card bordered={false}>
                            <Table dataSource={tableData} columns={tableColumns} />
                        </Card>
                    </Col>
                </Row>
            </Card>

            <Modal
                title={`Question Order for ${selectedReport}`}
                visible={questionOrderModalVisible}
                width={'80%'}
                onOk={handleQuestionOrderModalOk}
                onCancel={handleQuestionOrderModalCancel}
                footer={[
                    <Button
                        disabled={!save}
                        key="save" type="primary" onClick={saveQuestionOrderToTable}>
                        Save
                    </Button>,
                ]}
            >
                <ReactSortable
                    filter=".addImageButtonContainer"
                    dragClass="sortableDrag"
                    list={questions}
                    setList={setQuestions}
                    animation="200"
                    easing="ease-out"
                >
                    {
                        questions.length > 0 ?
                            questions.map((item, index) => (
                                <div key={index} className={`draggableItem ${styles.draggableItem}`}>{item.header}</div>
                            ))
                            : <div className="draggableItem">'No Results'</div>
                    }
                </ReactSortable>
            </Modal>
        </div>
    );
};

export default Review;
