import API_ENDPOINTS from '../../../apiConfig';
import React, { useState, useContext } from 'react';
import {Button, Card, message, notification, Typography, Select } from 'antd';
import { UserContext } from '../../UserContext';
import axiosInstance from '../../axiosInstance'; // Import the axiosInstance function
// import {Collapse, Space, InputNumber, Input, Popconfirm, Modal, Table} from 'antd';


const SummaryDrawer = ({
    selectedCollectionKey,
    selectedCollection,
  selectedTab,
  handleTemplateChange,
  selectedTemplateKey,
  templates
        

}) => {
    const { uid } = useContext(UserContext);
    const { token } = useContext(UserContext);
    const [docVectorDetails, setDocVectorsDetails] = useState({});
  const { Text } = Typography;
      const { Option } = Select;

    const fetchDocVectors = async () => {
        try {
          const response = await axiosInstance(token).post(
            `${API_ENDPOINTS.interactions}/summary1?user_id=${uid}&collection_id=${selectedCollectionKey}`
          );
    
          const { shape_docs, shape_processed_docs, shape_vectors } = response.data.data;
          setDocVectorsDetails({ shape_docs, shape_processed_docs, shape_vectors });
          message.success('Vector count fetched successfully!');
        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Error',
            description: 'Failed to retrieve vector details. Please try again later.',
          });
        }
      };

    
      return (
        <div>
                    {/* <div>
            <Select           
                showSearch
                // onSearch={onSearch}
                optionFilterProp="children"
                style={{ width: '100%' }} 
                value={selectedTemplateKey} 
                onChange={handleTemplateChange}>
              <Option value="">Select Template</Option>
              {templates.map((template) => (
                <Option key={template.key} value={template.key}>
                  {template.label}
                </Option>
              ))}
            </Select>
          </div> */}
                    {/* <Card  className="custom-card" hoverable title={<Text strong>Select Prompt Template</Text>}>

                        <Select           
                showSearch
                // onSearch={onSearch}
                optionFilterProp="children"
                style={{ width: '100%' }} 
                value={selectedTemplateKey} 
                onChange={handleTemplateChange}>
              <Option value="">Select Template</Option>
              {templates.map((template) => (
                <Option key={template.key} value={template.key}>
                  {template.label}
                </Option>
              ))}
            </Select>
          </Card> */}
          <Card  className="custom-card" hoverable title={<Text strong>Collection Summaries Settings</Text>}>
                <Card
                    title="Vector Details"
                    size='small'
                    extra={[
                        <Button size="small" onClick={fetchDocVectors}>Fetch Vector Count</Button>
                    ]}>
                    <div>
                        <Text strong>Document Count: </Text><Text> {docVectorDetails.shape_docs}</Text><br></br>
                        <Text strong>Processed Vectors: </Text><Text>{docVectorDetails.shape_processed_docs}</Text><br></br>
                    </div>
                </Card>
                <br></br>
          </Card>
        </div>
      );
    };
    
    export default SummaryDrawer;