
import API_ENDPOINTS from '../apiConfig';
import React, { useState, useContext, useEffect, useCallback } from 'react';
import { Button, message, Select, Card, Typography, Input, Tabs, Row, Col, Popconfirm, Space } from 'antd';
import axiosInstance from '../Components/axiosInstance';
import { UserContext } from '../Components/UserContext';
import PromptTemplate from "../Components/Templates/PromptTemplate";
import ReportTemplate from "../Components/Templates/ReportTemplate";

import styles from '../styles/Collections.module.css';
import 'antd/dist/reset.css';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

export default function Templates ( {
  templates,
  setTemplates,
  jsonData,
  setJsonData,
  selectedTemplateKey,
  setSelectedTemplateKey,
  updatePromptVariables,
  setUpdatePromptVariables,
  updatePromptTemplate,
  setUpdatePromptTemplate,
  selectedTemplateLabel,
  setSelectedTemplateLabel,
  selectedTab,
  setSelectedTab,
  reportTemplates,
  setReportTemplates,
  selectedReportTemplateKey,
  setSelectedReportTemplateKey,
}) {
  const { uid } = useContext(UserContext);
  const { token } = useContext(UserContext);

  const handleTabChange = (key) => {
    setSelectedTab(key);
}

  const fetchTemplates = useCallback(() => {
    axiosInstance(token)
      .get(`${API_ENDPOINTS.templates}?user_id=${uid}`)
      .then((response) => {
        
        setTemplates(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token, uid]);

  const createTemplate = () => {
    const nsname = prompt('Enter a name for the new template:');
    if (nsname) {
      const dataToSend = {
        template_name: nsname,
        user_id: uid
      };
      axiosInstance(token)
        .post(API_ENDPOINTS.templates, dataToSend)
        .then((response) => {
          console.log('Template created ', response.data);
          fetchTemplates();
          setSelectedTemplateKey(response.data.template_key);
          message.success('Template created');
        })
        .catch((error) => {
          console.log(error);
          message.error('Template not created');
        });
    }
  };

  const renameTemplate = () => {
    const templateKey = selectedTemplateKey;
    const label = templates.find((template) => template.key === templateKey)?.label;
    const newLabel = prompt(`Enter a new name for ${label}:`);
    if (newLabel) {
      const dataToSend = {
        user_id: uid,
        template_key: templateKey,
        new_template_name: newLabel
      };
      axiosInstance(token)
        .patch(API_ENDPOINTS.templates, dataToSend)
        .then((response) => {
          console.log(`Template ${templateKey} renamed to ${newLabel}`);
          fetchTemplates();
          message.success('Template renamed');
        })
        .catch((error) => {
          console.log(error);
          message.error('Template not renamed');
        });
    }
  };

  const deleteTemplate = () => {
    const templateKey = selectedTemplateKey;
    const label = templates.find((template) => template.key === templateKey)?.label;
    const confirmDelete = window.confirm(`Are you sure you want to delete the template "${label}"?`);
  
    if (confirmDelete) {
      const dataToSend = {
        user_id: uid,
        template_key: templateKey
      };
      console.log(dataToSend)
      axiosInstance(token)
        .delete(API_ENDPOINTS.templates, {"data": dataToSend})
        .then((response) => {
          console.log('Template deleted ', response);
          // Reset selectedTemplateKey to clear the selection
          setSelectedTemplateKey('');
          fetchTemplates(); // Fetch updated list of templates
          message.success('Template deleted');
        })
        .catch((error) => {
          console.log(error);
          message.error('Template not deleted');
        });
    }
  };

  const pullTemplate = useCallback( async () =>
  {
    const label = selectedTemplateLabel;
    message.loading('Loading template');
    console.log(`Pulling template (${label})`);
  
    axiosInstance(token)
      .get(`${API_ENDPOINTS.templatesUpload}?user_id=${uid}&tempalte_key=${selectedTemplateKey}`)
      .then((response) => {
        const result = response.data.data;
        console.log(result);
        setUpdatePromptTemplate(result[0].prompt_template.prompt);
  
        const inputVariables = result[0].prompt_template.input_variables;
        const inputVariablesString = inputVariables.join(', ');
        setUpdatePromptVariables(inputVariablesString);
        message.success('Template loaded');
      })
      .catch((error) => {
        console.log(error);
        message.error('Template not uploaded - did you select a template?');
      });
  }, [token, uid, selectedTemplateKey, selectedTemplateLabel]);
  
  
  const postData = async () => {
    const url = `${API_ENDPOINTS.templatesUpload}?user_id=${uid}&template_key=${selectedTemplateKey}`;
  
    try {
      let data;
  
      if (updatePromptVariables.trim() === '') {
        // Set input_variables as an empty JSON object
        data = {
          interaction: "qasource",
          input_variables: [],
          prompt: updatePromptTemplate
        };
      } else {
        data = {
          interaction: "qasource",
          input_variables: updatePromptVariables.split(',').map(variable => variable.trim()),
          prompt: updatePromptTemplate
        };
      }
  
      const response = await axiosInstance(token).post(url, data);
      console.log(response.request);
      console.log(data);
      console.log(response.data);
      message.success('Template uploaded');
    } catch (error) {
      console.error(error);
      message.error('Template not uploaded');
    }
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };

  return (
    <div className={styles.mainContainer}>
          <Row gutter={20}>
            {/*  Section 1 */}
            <Col span={24} height={'100%'} width={'100%'} align={'center'}>
              <Card hoverable>
                <Tabs size="large"
                  tabPosition="top"
                  defaultActiveKey="1"
                >
                    <Tabs.TabPane tab={<h3>Select Prompt Template</h3>} key="1">
                <PromptTemplate
                  templates={templates}
                            setTemplates={setTemplates}
                            jsonData={jsonData}
                            setJsonData={setJsonData}
                            selectedTemplateKey={selectedTemplateKey}
                            setSelectedTemplateKey={setSelectedTemplateKey}
                            updatePromptVariables={updatePromptVariables}
                            setUpdatePromptVariables={setUpdatePromptVariables}
                            updatePromptTemplate={updatePromptTemplate}
                            setUpdatePromptTemplate={setUpdatePromptTemplate}
                            selectedTemplateLabel={selectedTemplateLabel}
                            setSelectedTemplateLabel={setSelectedTemplateLabel}>
                          </PromptTemplate>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={<h3>Select Report Template</h3>} key="2">
                <ReportTemplate
                            templates={reportTemplates}
                            setTemplates={setReportTemplates}
                            selectedTemplateKey={selectedReportTemplateKey}
                            setSelectedTemplateKey={setSelectedReportTemplateKey}
                          ></ReportTemplate>
                      </Tabs.TabPane>
                    </Tabs>
                  </Card>
                </Col>
              </Row>
          </div>);
};


