import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { Button, Card, Divider, Table, Space, Modal, Input, Select, notification, Popconfirm, Row, Col, Spin, Typography } from 'antd';
import axiosInstance from '../axiosInstance';
import API_ENDPOINTS from '../../apiConfig';
import { UserContext } from '../UserContext';
import ReportQuestionsForm from '../ReportQuestionsForm'
const ReportSetup = ({
    selectedCollectionKey,
    collections,
    reports,
    templates,
    setTemplates,
    fetchReports,
    setCurrentStep,
    setSelectedCollectionKey,
    selectedReportKey,
    setSelectedReportKey,
    selectedReport,
    setSelectedReport,
    reportLoading,
    questions,
    setQuestions,
    selectedTemplateKey,
    setSelectedTemplateKey,
    ...otherProps
}) => {
    const { uid } = useContext(UserContext);
    const { token } = useContext(UserContext);
    const [templateModalVisible, setTemplateModalVisible] = useState(false);
    const [reportModalVisible, setReportModalVisible] = useState(false);
    const [questionModalVisible, setQuestionModalVisible] = useState(false);
    const [reportName, setReportName] = useState('');
    // const [collectionKey, setCollectionKey] = useState(selectedCollectionKey);
    const [description, setDescription] = useState('');

    const [reportEditing, setReportModalEditing] = useState(false);
    const [questionModalEditing, setQuestionModalEditing] = useState(false);
    const [editReportKey, setEditReportKey] = useState(selectedReportKey);
    const [questionInput, setQuestionInput] = useState([]);
    const [filteredInfo] = useState({});

    const [templatePrompt, setTemplatePrompt] = useState("");
    const [templateInputs, setTemplateInputs] = useState({});

    const { Option } = Select;


    const showReportModal = () => {
        setReportModalVisible(true);
        setReportName(''); // Set default value for report name
        // setCollectionKey(''); // Set default value for collection key
        // setSelectedCollectionKey('')
        setDescription(''); // Set default value for description
    };


    const handleReportModalOk = () => {
        // Create the base payload
        const reportPayload = {
            user_id: uid,
            description: description,
            report_name: reportName,
        };

        console.log(reportPayload)
        if (reportEditing) {
            reportPayload.report_id = editReportKey;

            // PATCH API call for updating report
            axiosInstance(token)
                .patch(API_ENDPOINTS.reports, reportPayload)
                .then((response) => {
                    fetchReports();
                    setReportModalVisible(false);
                    setReportModalEditing(false);
                    notification.success({ message: 'Report updated successfully' });
                })
                .catch((error) => {
                    notification.error({ message: 'Error', description: 'Failed to update report' });
                });
        } else {

            // POST API call for creating report
            axiosInstance(token)
                .post(API_ENDPOINTS.reports, reportPayload)
                .then((response) => {
                    fetchReports();
                    setReportModalVisible(false);
                    notification.success({ message: 'Report created successfully' });
                })
                .catch((error) => {
                    notification.error({ message: 'Error', description: 'Failed to create report' });
                });
        }
    };

    const handleReportModalCancel = () => {
        setReportModalVisible(false);
        setReportModalEditing(false);
    };

    const handleDelete = (reportKey) => {
        // DELETE API call for deleting report
        axiosInstance(token)
            .delete(`${API_ENDPOINTS.reports}?user_id=${uid}&report_id=${reportKey}`)
            .then((response) => {
                fetchReports();
                notification.success({ message: 'Report deleted successfully' });
            })
            .catch((error) => {
                notification.error({ message: 'Error', description: 'Failed to delete report' });
            });
    };

    const handleReportEdit = (record) => {
        setReportName(record.report_name);
        setSelectedReport(record.report_name);
        setEditReportKey(record.report_id);
        setSelectedReportKey(record.report_id);
        setDescription(record.description);
        setReportModalEditing(true);
        setReportModalVisible(true);
    };

    const handleReportPreview = (record) => {
        setReportName(record.report_name);
        setSelectedReport(record.report_name);
        setEditReportKey(record.report_id);
        setSelectedReportKey(record.report_id);
        setDescription(record.description);
        setCurrentStep(1);
    };

    const handleQuestionModalOk = () => {
        loadQuestions();
        setQuestionModalVisible(false);
        setQuestionModalEditing(false);
    };

    const handleQuestionModalCancel = () => {
        setQuestionModalVisible(false);
        // setQuestionsLoaded(false);  
        setQuestionModalEditing(false);
    };

    const handleQuestionEdit = (record) => {
        setQuestionModalEditing(true);
        setQuestionModalVisible(true);
        setReportName(record.report_name);
        setSelectedReport(record.report_name);
        setEditReportKey(record.report_id);
        setSelectedReportKey(record.report_id);
        setDescription(record.description);
    };

    const generateReportPromptFromTemplate = (str, variables) => {
        return str.replace(/\{([^}]+)\}/g, (match, key) => {
            return variables[key] || match; // Return the matched key's value from the variables, or the original string if not found
        });
    };



    const handleTemplateModalOk = () => {
        // set new question from template
        const template = JSON.parse(generateReportPromptFromTemplate(templatePrompt, templateInputs));
        setQuestionInput(template);
        setTemplateModalVisible(false);
    };

    const handleTemplateModalCancel = () => {
        setTemplateModalVisible(false);
    };

    const handleTemplateInputUpdate = (key, value) => {
        setTemplateInputs(prevState => ({
            ...prevState,
            [key]: value
        }));
    };


    const handleTemplateSelected = (value) => {
        setSelectedTemplateKey(value);
        var template = templates.find(template => template.template_id === value);
        setTemplatePrompt(template.prompt)
        const emptyDict = template["input_variables"]?.reduce((acc, currentValue) => {
            acc[currentValue] = ":p";
            return acc;
        }, {});
        setTemplateInputs(emptyDict);
    }

    const loadQuestions = () => {
        axiosInstance(token)
            .get(`${API_ENDPOINTS.reports}/questions?user_id=${uid}&report_id=${editReportKey}`)
            .then((response) => {
                setQuestions(response.data.data.questions);
                console.log("response.data.data.questions", response.data.data.questions)
                const formattedQuestions = response.data.data.questions.map(question => {
                    return {
                        ...question,
                        header: question.header,
                        question_detail: question.question_detail
                    }
                }
                )
                setQuestionInput(formattedQuestions);
            })
            .catch(error => console.log('API error:', error));
    };


    const handleQuestionInputChange = useCallback((e) => {
        console.log("input change", e.target.value)

    }, []);


    const updateQuestions = (e) => {
        console.log('updateQuestions e:', e);
        const questionList =
            e
                .map((form, index) => {
                    return {
                        question_id: form?.question_id || '',
                        question_detail: form.question_detail,
                        header: form.header,
                        interaction_id: form?.interaction_id || '',
                        // REFACTOR: Shouldn't this be handled by back-end?
                        order: form?.order || index + 1,
                        feedback: false,
                        response_detail: form?.response_detail || '',
                        visual_path: form?.visual_path
                            ? JSON.parse(form?.visual_path)
                            : '',
                        qasources: form?.qasources || {},
                        sumsources: form?.sumsources || '',
                        formatted_response_path: form?.formatted_response_path || '',
                        template_key: form?.template_key || '',
                        collection_id: form?.collection_id || null,
                        edit_flag: form?.edit_flag || false,
                        file_key: form?.file_key || null,
                    }
                })
        const payload = { questions: questionList };
        axiosInstance(token)
            .post(`${API_ENDPOINTS.reports}/questions?user_id=${uid}&report_id=${editReportKey}`, payload)
            .then(response => {
                console.log('API response:', response.data);
                fetchReports();
                notification.success(
                    {
                        message: 'Success',
                        description: 'Questions updated successfully'

                    }
                );
            })
            .catch(error => {
                notification.error({ message: 'Error', description: 'Failed to update questions' });
                console.log('API error:', error)
            });
    };

    useEffect(() => {
        if (questionModalVisible) {
            loadQuestions();
        }
    }, [questionModalVisible]);

    const columns = useMemo(() => [
        {
            title: 'Report',
            dataIndex: 'report_name',
            filteredValue: filteredInfo.label || null,
            onFilter: (value, record) => record.label.includes(value),
            ellipsis: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
        },
        {
            title: 'Question Count',
            dataIndex: 'question_count',
            align: 'center',
            width: 50,
        },
        {
            title: 'Actions',
            width: 100,
            align: 'center',
            render: (text, record) => {
                return (
                    <span>
                        <Space>
                            <Button size="small" onClick={() => handleReportPreview(record)}>Preview</Button>
                            <Button size="small" onClick={() => handleReportEdit(record)}>Edit</Button>
                            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.report_id)}>
                                <Button size="small">Delete</Button>
                            </Popconfirm>
                            <Button size="small" onClick={() => handleQuestionEdit(record)}>Update Questions</Button>
                        </Space>
                    </span>
                );
            },
        },
    ], [filteredInfo]);

    return (

        <div>
            <Row gutter={2} justify="space-between" align="middle">
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Card>
                        <Spin spinning={reportLoading} tip="Fetching reports!">
                            <div>
                                <div style={{ display: 'flex', marginBottom: '20px', gap: '1rem', justifyContent: 'right' }}>
                                    <Button type="default" onClick={fetchReports}>Refresh</Button>
                                    <Button type="primary" onClick={showReportModal}>Create Report</Button>
                                </div>
                                <div>
                                    <Table
                                        scroll={{ x: 'max-content' }}
                                        columns={columns}
                                        bordered
                                        dataSource={reports}

                                        rowKey="key"
                                    />
                                </div>
                            </div>
                        </Spin>
                    </Card>
                </Col>
            </Row>
            <Modal
                title={reportEditing ? `Edit Details for ${reportName}` : 'Create Report'}
                visible={reportModalVisible}
                width={"40%"}
                onOk={handleReportModalOk}
                onCancel={handleReportModalCancel}
            >
                <Divider />
                <Typography.Text>Report Name</Typography.Text>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Input
                        placeholder="Report Name"
                        value={reportName}
                        onChange={e => setReportName(e.target.value)}
                    />
                    <Typography.Text>Report Description</Typography.Text>
                    <Input
                        placeholder="Report Description"
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                    />
                </Space>
            </Modal>
            <Modal
                title={`Update Questions for ${reportName}`}
                visible={questionModalVisible}
                onCancel={handleQuestionModalCancel}
                size="large"
                width={"80%"}
                footer={null}
            >
                <div>
                    <br></br>
                    <Card>
                        <ReportQuestionsForm
                            questionInput={questionInput}
                            collections={collections}
                            updateQuestions={updateQuestions}
                            handleQuestionModalOk={handleQuestionModalOk}
                            handleQuestionModalCancel={handleQuestionModalCancel}
                            setTemplateModalVisible={setTemplateModalVisible}
                            isTemplate={false}
                        ></ReportQuestionsForm>

                    </Card>
                </div>
            </Modal>
            <Modal
                title={`Select template for ${reportName}`}
                visible={templateModalVisible}
                onOk={handleTemplateModalOk}
                onCancel={handleTemplateModalCancel}
                size="medium"
                width={"60%"}
            >
                <Divider />
                <Select
                    placeholder="Select template"
                    value={selectedTemplateKey}
                    style={{ width: '100%' }}
                    onSelect={value => handleTemplateSelected(value)}
                >
                    {templates.map((template) => (
                        <Option key={template.template_id} value={template.template_id}>
                            {template.name}
                        </Option>
                    ))}
                </Select>
            </Modal>
        </div>

    );
};

export default React.memo(ReportSetup);
