import React, { useState, useContext, useRef, useEffect } from 'react';
import { Button, Form, Input, Popconfirm, Table, Space, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleRowSave,
    handleDropdownChange,
    collections,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        if (!['collection_id', 'interaction_id', 'file_key'].includes(dataIndex)) {
            setEditing(!editing);
            form.setFieldsValue({ [dataIndex]: record[dataIndex] });
        }
    };

    const save = async (field, newValue) => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleRowSave({ ...record, ...values, [field]: newValue });

            if (['interaction_id', 'collection_id', 'file_key'].includes(field)) {
                handleDropdownChange(record.key, field, newValue);
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        if (['interaction_id', 'collection_id', 'file_key'].includes(dataIndex)) {
            const options = dataIndex === 'interaction_id' ?
                ["Q&A", "Summary", "Tabular CSV", "Comment Parser", "Lead Generator"].map(opt => ({ key: opt, label: opt })) :
                dataIndex === 'collection_id' ?
                    collections.map(col => ({ key: col.collection_id, label: col.collection_name })) :
                    collections.find(col => col.collection_id === record.collection_id)?.files.map(file => ({ key: file.file_key, label: file.file_name })) || [];

            const isFileDropdownDisabled = dataIndex === 'file_key' && record.interaction_id === 'Q&A';

            childNode = (
                <Select
                    value={record[dataIndex]}
                    onChange={(newValue) => {
                        form.setFieldsValue({ [dataIndex]: newValue });
                        save(dataIndex, newValue);
                    }}
                    style={{ width: '100%' }}
                    disabled={isFileDropdownDisabled}
                >
                    {options.map(option => (
                        <Select.Option key={option.key} value={option.key}>
                            {option.label}
                        </Select.Option>
                    ))}
                </Select>
            );
        } else {
            childNode = editing ? (
                <Form.Item
                    style={{ margin: 0 }}
                    name={dataIndex}
                    rules={[{ required: true, message: `${title} is required.` }]}
                >
                    {dataIndex === 'question_detail' ?
                        <TextArea ref={inputRef} onPressEnter={save} onBlur={save} rows={4} />
                        : <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                    }
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }
    }

    return <td {...restProps}>{childNode}</td>;
};

const CustomDropdown = ({ value, onChange, options }) => (
    <Select value={value} onChange={onChange} style={{ width: '100%' }}>
        {options.map(option => (
            <Select.Option key={option.key} value={option.key}>
                {option.label}
            </Select.Option>
        ))}
    </Select>
);

export default function ReportQuestionsForm({
    collections,
    style,
    formName,
    questionInput,
    updateQuestions,
    setUpdatePromptVariables,
    setUpdatePromptTemplate,
    isTemplate,
    patchTemplate,
    fetchTemplate,
    handleQuestionModalOk,
    handleQuestionModalCancel,
    setTemplateModalVisible
}) {
    const [dataSource, setDataSource] = useState(questionInput);
    const interactionOptions = [
        "Q&A",
        "Summary",
        "Tabular CSV",
        "Comment Parser",
        "Lead Generator"
    ].map(opt => ({ key: opt, label: opt }));

    useEffect(() => {
        const dataWithKeys = questionInput.map((item, index) => ({
            ...item,
            key: `item_${index}`
        }));
        setDataSource(dataWithKeys);
    }, [questionInput]);

    const handleRowAdd = () => {
        const newData = {
            key: `item_${dataSource.length}`,
            header: `header_${dataSource.length}`,
            question_detail: `question_${dataSource.length}`,
        };
        setDataSource([...dataSource, newData]);
    };

    const handleRowSave = (row) => {
        const newData = dataSource.map(item => (item.key === row.key ? { ...item, ...row } : item));
        setDataSource(newData);
    };

    const handleRowDelete = (key) => {
        const newData = dataSource.filter(item => item.key !== key);
        setDataSource(newData);
    };

    const handleDropdownChange = (key, field, newValue) => {
        const newData = dataSource.map(item => {
            if (item.key === key) {
                const updatedItem = { ...item, [field]: newValue };
                if (field === 'interaction_id' && newValue === 'Q&A') {
                    updatedItem.file_key = null;
                }
                if (field === 'collection_id') {
                    updatedItem.file_key = null;
                }
                return updatedItem;
            }
            return item;
        });
        setDataSource(newData);
    };

    const columns = [
        {
            title: 'Header',
            dataIndex: 'header',
            editable: true,
            width: 250,
        },
        {
            title: 'Question Detail',
            dataIndex: 'question_detail',
            editable: true,
        },
        {
            title: 'Interaction',
            dataIndex: 'interaction_id',
            editable: true,
            width: 170,
            render: (_, record) => (
                <CustomDropdown
                    value={record.interaction_id}
                    onChange={(newValue) => handleDropdownChange(record.interaction_id, 'interaction_id', newValue)}
                    options={interactionOptions}
                />
            ),
        },
        {
            title: 'Collection',
            dataIndex: 'collection_id',
            editable: true,
            width: 230,
            render: (_, record) => (
                <Select
                    value={record.collection_id}
                    onChange={(newValue) => handleDropdownChange(record.key, 'collection_id', newValue)}
                    style={{ width: '100%' }}
                >
                    {collections.map(col => (
                        <Select.Option key={col.collection_id} value={col.collection_id}>
                            {col.collection_name}
                        </Select.Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'File',
            dataIndex: 'file_key',
            editable: true,
            width: 230,
            render: (_, record) => (
                <CustomDropdown
                    value={record.file_key}
                    onChange={(newValue) => handleDropdownChange(record.file_key, 'file_key', newValue)}
                    options={record.collection_id ? collections.find(col => col.key === record.collection_id)?.files.map(file => ({ key: file.file_key, label: file.file_name })) : []}
                    disabled={record.interaction_id === 'Q&A'}
                />
            ),
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            width: 100,
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleRowDelete(record.key)}>
                        <a>Delete</a>
                    </Popconfirm>
                ) : null,
        },
    ].map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: record => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleRowSave,
                handleDropdownChange,
                collections
            }),
        };
    });

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const handleSubmit = () => {
        if (dataSource) {
            updateQuestions(dataSource);
        }
        console.log(dataSource);
        handleQuestionModalOk();
    };

    return (
        <div style={style || { width: '100%' }}>
            <Button
                onClick={handleRowAdd}
                type='primary'
                style={{ marginBottom: 16, marginRight: 8 }}
            >
                Add Question
            </Button>
            {!isTemplate && (
                <Button
                    onClick={setTemplateModalVisible}
                    type='primary'
                    style={{ marginBottom: 16 }}
                >
                    Load from Template
                </Button>
            )}
            <Form
                name={formName}
                initialValues={{ questions: dataSource }}
            >
                <Table
                    tableLayout='fixed'
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource}
                    columns={columns}
                    rowKey="key"
                    pagination={{ pageSize: 5 }}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Form.Item style={{ margin: 0 }}>
                        <Button
                            onClick={handleQuestionModalCancel}
                            style={{ marginRight: 8 }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}
