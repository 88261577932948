import React, { useRef, useEffect } from 'react';
import { WordCloud } from '@antv/g2plot';

const WordCloudComponent = ({ commentResult }) => {
    const wordCloudRef = useRef(null);
    const wordCloudInstance = useRef(null);

    useEffect(() => {
        // Clear existing word cloud
        if (wordCloudInstance.current) {
            wordCloudInstance.current.destroy();
            wordCloudInstance.current = null;
        }
        wordCloudRef.current.innerHTML = '';

        if (commentResult && commentResult.length > 0 && wordCloudRef.current) {
            try {
                const wordCloudList = commentResult.map(keyword => ({
                    name: keyword.keyword, // Ensure these fields match your data structure
                    value: keyword.comments.length,
                }));

                // Create a new word cloud
                const config = {
                    autoFit: true,
                    data: wordCloudList,
                    wordField: 'name',
                    weightField: 'value',
                    colorField: 'name',
                    wordStyle: {
                        fontFamily: 'Verdana',
                        fontSize: [20, 60],
                    },
                    tooltip: {
                        visible: true,
                    },
                    random: () => 0.5,
                };

                wordCloudInstance.current = new WordCloud(wordCloudRef.current, config);
                wordCloudInstance.current.render();

            } catch (error) {
                console.error('Error rendering word cloud:', error);
            }
        }

        return () => {
            // Cleanup on component unmount
            if (wordCloudInstance.current) {
                // Ensure that the container still exists before attempting to destroy the instance
                if (wordCloudRef.current && wordCloudRef.current.parentNode) {
                    wordCloudInstance.current.destroy();
                }
                wordCloudInstance.current = null;
            }
        };
    }, [commentResult]);

    return <div ref={wordCloudRef} />;
};

export default WordCloudComponent;
